import { useEffect, useState } from 'react';
import { useAxios } from '../../hooks/useAxios';
import { format } from 'date-fns';
import { Console } from '../../services/platform-api';

const AssistantConfig = (props) => {
  const [fetchSetting, setFetchSetting] = useState(''); // save_config get_config got_config
  const assistant_id = props?.assistant_id;

  const { loading: config_loading, data, fetchNow } = useAxios();
  const [tryGetConfig, setTryGetConfig] = useState(true);
  const [wf_config, setWFConfig] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [wf_configSet, setWFConfigSet] = useState(false);
  const [initialConfigCreate, setInitialConfigCreate] = useState(true);
  const [configAlertType, setConfigAlertType] = useState('');
  const [configAlertContent, setConfigAlertContent] = useState('');
  const [notFound, setCustomerNotFound] = useState(false);
  const [configChanged, setConfigChanged] = useState(false);
  const [wFConfigHistoryChangedAt, setWFConfigHistoryChangedAt] = useState(null);
  const [wFConfigHistoryChangedBy, setWFConfigHistoryChangedBy] = useState(null);

  useEffect(() => {
    if (data?.id === assistant_id && fetchSetting === 'save_config') {
      setWFConfigSet(true);
      setConfigAlertType('success');
      setConfigAlertContent('Configuration Saved');
      setTimeout(() => {
        setConfigAlertType('');
        setConfigAlertContent('');
      }, 3000);
      setInitialConfigCreate(false);
      setFetchSetting('');
    }

    if (data && fetchSetting === 'get_config') {
      if (data?.config) {
        setWFConfig(data?.config);
      }

      if (data?.last_history?.date) {
        const logs_config_changed_at = format(new Date(data?.last_history?.date), 'PPPpp');
        setWFConfigHistoryChangedAt(logs_config_changed_at);
        setWFConfigHistoryChangedBy(data?.last_history?.changed_by);
      }
      setCustomer(data.user);
      setWFConfigSet(true);
      setInitialConfigCreate(false);
      setCustomerNotFound(false);
    }

    if (data && data.status === 'error') {
      setCustomerNotFound(true);
      return;
    }
    // get the config to show the user on page load
    if (!wf_config && tryGetConfig && assistant_id) {
      get_config();
      setTryGetConfig(false); // we only try one time. If there is no config then nothing to do till user creates it.
    }
  }, [data, fetchSetting, wf_config, assistant_id, configChanged]);

  const get_config = () => {
    setFetchSetting('get_config');
    fetchNow(`/api/platform/assistant/${assistant_id}`, 'GET', null, false);
  };

  const save_wf_config = (payload) => {
    setFetchSetting('save_config');

    if (initialConfigCreate) {
      payload.assistant_id = assistant_id;
      fetchNow(`/api/platform/assistant`, 'POST', payload, false);
    } else {
      fetchNow(`/api/platform/assistant/${assistant_id}/`, 'PUT', payload, false);
    }
    setConfigChanged(false);
  };

  return {
    get_config,
    wf_config,
    wFConfigHistoryChangedAt,
    wFConfigHistoryChangedBy,
    setWFConfig,
    save_wf_config,
    fetchSetting,
    setFetchSetting,
    configAlertType,
    configAlertContent,
    configChanged,
    setConfigChanged,
    wf_configSet,
    customer,
    notFound,
    config_loading,
  };
};

export { AssistantConfig };
