import React from 'react';

const LayoutTabItems = (props) => {
  const recipe_tabs = [
    {
      name: 'Recipe',
      link: 'recipe',
    },
    {
      name: 'Jobs',
      link: 'jobs',
    },
    {
      name: 'Connections',
      link: 'connections',
    },
  ];

  const assistant_admin_tabs = [
    {
      name: 'Key Status',
      link: 'assistant-status',
    },
    {
      name: 'Jobs Details',
      link: 'assistant-job-details',
    },
  ];

  const my_account_tabItems = [
    {
      name: 'My Details',
      link: 'my-details',
    },
    {
      name: 'Password',
      link: 'password-form',
    },
  ];

  let tabItems;
  let active;
  if (props.tabGroup === 'myaccount') {
    tabItems = my_account_tabItems;
    active = 'my-details';
  } else if (props.tabGroup === 'assistant') {
    tabItems = assistant_admin_tabs;
    active = 'assistant-status';
  } else if (props.tabGroup === 'recipe') {
    tabItems = recipe_tabs;
    active = 'recipe';
  }

  return (
    <>
      <div className="tabbed-wrapper">
        <ul>
          {tabItems.map((tab, key) => (
            <li key={key} className={`${tab.link === active ? 'active' : ''}`}>
              <a href={tab.link} onClick={(e) => props.changeTab(e, tab.link)} rel="noopener">
                {tab.name}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export { LayoutTabItems };
