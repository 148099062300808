import React, { useEffect, useState } from 'react';
import { LayoutDashboard } from '../../layouts/layout-dashboard';
import { Nav } from '../../components/nav';
import { Breadcrumb } from '../../components/breadcrumb';
import { CardSolution } from '../../components/cardsolution';
import { Container, Row, Col } from 'react-bootstrap';
import { useAxios } from '../../hooks/useAxios';
import { Preloader } from '../../components/preloader';

/*
 * assistant_type is hard coded to 'archie' for time being as its the only one we have
 */
const Solutions = (props) => {
  const { loading, data, error, fetchNow } = useAxios();
  const [solutionsData, setSolutionsData] = useState([]);

  useEffect(() => {
    fetchNow(`/api/platform/solutions`, 'GET', null, false);
  }, [fetchNow]);

  useEffect(() => {
    if (data) {
      setSolutionsData(data);
    }
  }, [data]);

  if (error) {
    console.log('error', error);
    return null;
  }

  return (
    <>
      <div className="dashboard">
        <header>
          <Container fluid>
            <Row>
              <Col lg={8} className="px-5">
                <Breadcrumb label={'Home'} />
                <h1>Home</h1>
              </Col>
              <Col lg={4} className="px-5"></Col>
            </Row>
          </Container>
        </header>

        <LayoutDashboard>
          <Row>
            {loading ? (
              <Preloader />
            ) : (
              solutionsData &&
              solutionsData?.map((solutionData) => {
                return (
                  <Col lg={6} key={solutionData.id} className="flex">
                    <CardSolution
                      icon={`/images/${solutionData.icon}`}
                      title={solutionData.name}
                      content={solutionData.descrption}
                      id={solutionData.id}
                      status={solutionData.status}
                      assistant_type={solutionData.id === 1 ? 'archie' : ''}
                    />
                  </Col>
                );
              })
            )}
          </Row>
        </LayoutDashboard>

        <Nav nav1on="on" nav2on="" nav3on="" {...props} />
      </div>
    </>
  );
};

export { Solutions };
