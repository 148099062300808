import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { LayoutDashboard } from '../../layouts/layout-dashboard';
import { Nav } from '../../components/nav';
import { Breadcrumb } from '../../components/breadcrumb';
import { Row, Col, Container, Tabs, Tab, Button } from 'react-bootstrap';
import { useAxios } from '../../hooks/useAxios';
import { Preloader } from '../../components/preloader';
import { ArchieConfig } from '../../data/platform/archie_config';
import Alert from 'react-bootstrap/Alert';
import { Console } from '../../services/platform-api';
import { XeroConnect, XeroDisconnect } from '../../assets/images/icons/icons';
import { formatTimestamp } from '../../layouts/logHelpers';
import isEqual from 'lodash.isequal';

const SetupArchieMain = (props) => {
  const [searchParams] = useSearchParams();

  const status_success = 'success';
  const status_error = 'error';
  const status_disconnected = 'disconnected';
  const status_connected = 'connected';

  let subscriptionId = searchParams.get('subscriptionId');
  const get_xero_auth_url = '/api/platform/provider/xero/authorize';
  const get_xero_status_url = '/api/platform/provider/xero/status';
  const get_xero_disconnect_url = '/api/platform/provider/xero/disconnect';
  const get_xero_subscription_details_url = '/api/platform/provider/xero/subscription_info/';

  const { data: authUrlResp, error, fetchNow: getAuthURL } = useAxios();
  const { loading: loadingStatus, data: xeroAuthStatus, fetchNow: getXeroConnectionStatus } = useAxios();
  const { loading: loadingRemove, data: xeroDisconnectStatus = null, fetchNow: removeXeroConnection } = useAxios();
  const {
    loading: loadingSubscription,
    data: xeroSubscriptionDetails,
    fetchNow: getXeroSubscriptionDetails,
  } = useAxios();

  const [approversEmail, setApproversEmail] = useState('');
  const [forwardEmail, setForwardEmail] = useState('');
  const [archieEmailAddress, setArchieEmailAddress] = useState('');

  const [emailAlertContent, setEmailAlertContent] = useState('');
  const [emailAlertType, setEmailAlertType] = useState('');

  const [showEmailError, setShowEmailError] = useState(false);

  const [doingAuth, setDoingAuth] = useState(false);
  const [popup, setPopup] = useState(null);
  const [intervalId, setIntervalId] = useState(null);
  const [intervalIdSubscription, setIntervalIdSubscription] = useState(null);
  const [doingSubCheck, setDoingSubCheck] = useState(false);

  const [activeTab, setActiveTab] = useState('connect');

  const [alertVisibility, setAlertVisibility] = useState('d-none');
  const [alertVariant, setAlertVariant] = useState('warning');
  const [alertMessage, setAlertMessage] = useState('');
  const [subscriptionAlertType, setSubscriptionAlertType] = useState('');
  const [subscriptionAlertContent, setSubscriptionAlertContent] = useState('');

  const [xeroConnectionStatus, setXeroConnectionStatus] = useState('disconnected');
  const [updatedConnectionDateTime, setUpdatedConnectionDateTime] = useState('');
  const [initialSubscriptionDetails, setInitialSubscriptionDetails] = useState(null);
  const [savedInitialSubDetails, setSavedInitialSubDetails] = useState(false);
  const [subscriptionUpdated, setSubscriptionUpdated] = useState(false);

  const {
    get_config,
    archie_config,
    save_archie_config,
    fetchSetting,
    setFetchSetting,
    configAlertType,
    configAlertContent,
    configChanged,
    configSet,
  } = ArchieConfig();

  useEffect(() => {
    Console('first load');
    if (!configSet) {
      get_config();
    }
    checkXeroConnectionStatus();
    getXeroSubscriptionDetails(get_xero_subscription_details_url, 'GET', null, null, false);
  }, []);

  useEffect(() => {
    if (xeroSubscriptionDetails !== null && savedInitialSubDetails === false) {
      setSavedInitialSubDetails(true);
      setInitialSubscriptionDetails(xeroSubscriptionDetails);
    }
  }, [xeroSubscriptionDetails]);

  useEffect(() => {
    if (authUrlResp?.status === status_success) {
      openPopup(authUrlResp.auth_url);
    }
  }, [initialSubscriptionDetails]);

  // When Xero sends back a subscription id we take it and pass to the back end and wait for resposne
  useEffect(() => {
    if (subscriptionId) {
      setActiveTab('subscription');
      if (!subscriptionUpdated) {
        setSubscriptionAlertType('warning');
        setSubscriptionAlertContent(
          'Looking up your subscription details. This may take a few minutes to update if recently subscribed.',
        );

        setDoingSubCheck(true);
        doSubCheckLoop();
      }
    }
  }, [subscriptionId, subscriptionUpdated]);

  useEffect(() => {
    if (!doingSubCheck || xeroSubscriptionDetails === null || initialSubscriptionDetails === null) return;

    if (!isEqual(xeroSubscriptionDetails, initialSubscriptionDetails)) {
      // Data has changed, stop polling
      setSubscriptionAlertType('success');
      setSubscriptionAlertContent('Xero subscription details updated');

      setInitialSubscriptionDetails(xeroSubscriptionDetails);
      setSubscriptionUpdated(true);

      clearInterval(intervalIdSubscription);
      setIntervalIdSubscription(null);
      setDoingSubCheck(false);
    }
  }, [doingSubCheck, xeroSubscriptionDetails, initialSubscriptionDetails]);

  const doSubCheckLoop = () => {
    const sub_id = setInterval(() => {
      getXeroSubscriptionDetails(get_xero_subscription_details_url, 'GET', null, null, false);
    }, 15000);

    setIntervalIdSubscription(sub_id); // Store the interval ID for future use
  };

  useEffect(() => {
    if (archie_config && fetchSetting === 'get_config') {
      try {
        setApproversEmail(archie_config['email_address_approver']);
        setForwardEmail(archie_config['email_address_forward']);
        setArchieEmailAddress(archie_config['email_address_archie']);
      } catch (error) {
        Console('issue getting all fields');
      }

      setFetchSetting('');
    }
  }, [, archie_config, fetchSetting]);

  useEffect(() => {
    if (authUrlResp?.status === status_success) {
      openPopup(authUrlResp.auth_url);
    }
  }, [authUrlResp]);

  useEffect(() => {
    if (
      xeroAuthStatus?.status === status_success &&
      xeroAuthStatus?.connection_info?.connection_status === status_connected
    ) {
      setXeroConnectionStatus(status_connected);
      setAlertMessage('Xero successfully connected');
      setAlertVisibility('');
      setAlertVariant('success');
      setDoingAuth(false);
      closePopup();

      setTimeout(() => {
        setAlertMessage('');
        setAlertVisibility('d-none');
      }, 3000);

      const f_date = new Date(xeroAuthStatus.connection_info.updated_at).toLocaleDateString();
      const f_time = new Date(xeroAuthStatus.connection_info.updated_at).toLocaleTimeString();
      setUpdatedConnectionDateTime(`${f_date} ${f_time}`);
    } else if (xeroAuthStatus?.status === status_error && !doingAuth) {
      clearInterval(intervalId);
    }
  }, [xeroAuthStatus]);

  useEffect(() => {
    if (
      xeroDisconnectStatus?.status === status_success &&
      xeroDisconnectStatus?.connection_info?.connection_status === status_disconnected
    ) {
      setXeroConnectionStatus(status_disconnected);
      setAlertMessage(xeroDisconnectStatus?.connection_info?.message);
      setAlertVisibility('');
      setAlertVariant('success');
      setDoingAuth(false);
    } else if (xeroDisconnectStatus?.status === status_error) {
      setAlertMessage(xeroDisconnectStatus?.connection_info?.message);
      setAlertVisibility('');
      setAlertVariant('warning');
      setDoingAuth(false);
    }

    setTimeout(() => {
      setAlertMessage('');
      setAlertVisibility('d-none');
    }, 3000);
  }, [xeroDisconnectStatus]);

  useEffect(() => {
    if (configChanged) {
      handleTabSelect('inbox');
    }
  }, [configChanged]);

  useEffect(() => {
    return () => {
      // Cleanup interval on component unmount
      if (intervalId) {
        clearInterval(intervalId);
      }
      if (intervalIdSubscription) {
        clearInterval(intervalIdSubscription);
      }
    };
  }, [intervalId, intervalIdSubscription]);

  const startAuthFlow = () => {
    setDoingAuth(true);
    getAuthURL(get_xero_auth_url, 'GET', null, null, false);
  };

  const checkXeroConnectionStatus = () => {
    getXeroConnectionStatus(get_xero_status_url, 'GET', null, null, false);
  };

  const doDisconnectXero = () => {
    removeXeroConnection(get_xero_disconnect_url, 'GET', null, null, false);
  };

  const closePopup = () => {
    if (popup) {
      popup.close();
      setPopup(null);
      if (intervalId) {
        clearInterval(intervalId);
        setIntervalId(null);
      }
    }
  };

  const openPopup = (url) => {
    const width = 1000;
    const height = 600;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;

    const newPopup = window.open(url, 'XeroAuth', `width=${width},height=${height},top=${top},left=${left}`);

    setPopup(newPopup);

    const id = setInterval(() => {
      checkXeroConnectionStatus();
    }, 5000);

    setIntervalId(id);
  };

  const handleTabSelect = (tab) => {
    setActiveTab(tab);
  };

  const handleEmailUpdate = (key, value) => {
    const field = emailFields[key];
    if (field) {
      field.setter(value);
    }
  };

  const validateEmailAddress = () => {
    let isValid = true;
    Object.values(emailFields).forEach((field) => {
      const { value } = field;
      if (value) {
        if (archieEmailAddress !== null && value.includes(archieEmailAddress)) {
          setEmailAlertType('primary');
          setEmailAlertContent('This email address cannot be the same as the one Archie is using.');
          setShowEmailError(true);
          isValid = false;
        }
      }
    });

    return isValid;
  };

  const emailFields = {
    email_address_approver: {
      label: 'Supplier Approver Email',
      desc: 'When Archie receives an invoice from a new supplier, he should request approval from:',
      value: approversEmail,
      setter: setApproversEmail,
    },
    email_address_forward: {
      label: 'Forward Email',
      desc: 'When Archie can’t take care of an email in the inbox, he should forward it to:',
      value: forwardEmail,
      setter: setForwardEmail,
    },
  };

  const renderEmailFields = () => {
    return Object.entries(emailFields).map(([key, field]) => (
      <div key={key}>
        {' '}
        {/* Add a key prop with the unique key */}
        <label htmlFor={key}>{field.label}</label>
        <br />
        <span>{field.desc}</span>
        <br />
        <input
          type="email"
          required
          id={key}
          value={field.value}
          onChange={(e) => handleEmailUpdate(key, e.target.value)}
          placeholder="email@yourdomainname.here"
          className="form-control input-2col"
        />
        <hr />
      </div>
    ));
  };

  const save_this_config = () => {
    setShowEmailError(false);

    if (validateEmailAddress()) {
      const payload = { config: {} };
      Object.entries(emailFields).forEach(([key, field]) => {
        const { value } = field;
        if (value) {
          payload.config[key] = value;
        }
      });

      if (archieEmailAddress) {
        payload.config['email_address_archie'] = archieEmailAddress;
      }

      // consolidated fields into others
      // removed finance and manager so we need to still populate for time being.
      if (forwardEmail) {
        payload.config['email_address_finance'] = forwardEmail;
      }
      if (approversEmail) {
        payload.config['email_address_manager'] = approversEmail;
      }

      save_archie_config(payload);
    }
  };

  const renderContinueBtn = (tab) => {
    return (
      <Button
        variant="primary"
        type="submit"
        onClick={() => handleTabSelect(tab)}
        className="mt-3 px-4 right"
        disabled={xeroAuthStatus?.status !== status_success}
      >
        Continue
      </Button>
    );
  };

  const handleOpenLink = (link) => {
    // Open the URL in a new tab
    window.open(link, '_blank', 'noopener,noreferrer');
  };

  if (error) {
    console.log(status_error, error);
    return null;
  }

  return (
    <>
      <div className="dashboard">
        <header>
          <Container fluid>
            <Row>
              <Col lg={8} className="px-5">
                <Breadcrumb label={'Set Up Archie'} />
                <h1 id="setup-archie-title">Set Up Archie</h1>
              </Col>
              <Col lg={4} className="px-5"></Col>
            </Row>
          </Container>
        </header>

        <LayoutDashboard>
          <Row>
            <Col>
              <Tabs className="mb-0" activeKey={activeTab} onSelect={handleTabSelect}>
                <Tab eventKey="connect" title="Step 1: Connect Xero">
                  <Row>
                    <Col className="h-100vh">
                      <h3 className="mt-4">Step 1: Connect to your accounting system</h3>

                      <Alert className={alertVisibility} variant={alertVariant}>
                        {alertMessage}
                      </Alert>

                      {doingAuth || loadingStatus || loadingRemove ? (
                        <Preloader smallscreen={true} />
                      ) : xeroConnectionStatus === status_disconnected ? (
                        <>
                          {renderContinueBtn('colleagues')}

                          <Button variant="primary-xero" type="submit" onClick={startAuthFlow} className=" pr-4">
                            <img src={XeroConnect} alt="Connect to Xero" />
                          </Button>
                        </>
                      ) : (
                        xeroConnectionStatus === status_connected && (
                          <div>
                            <p>
                              <strong>Status:</strong> Connected!
                              <br />
                              <strong>Connected at:</strong> {updatedConnectionDateTime}
                              <br />
                              <strong>Connected to:</strong> {xeroAuthStatus?.connection_info?.tenant_name}
                            </p>

                            {renderContinueBtn('colleagues')}
                            <Button variant="primary-xero" type="submit" onClick={doDisconnectXero} className="pr-4">
                              <img src={XeroDisconnect} alt="Connect to Xero" />
                            </Button>
                          </div>
                        )
                      )}
                    </Col>
                  </Row>
                </Tab>
                <Tab
                  eventKey="colleagues"
                  title="Step 2: Contacts"
                  disabled={xeroAuthStatus?.status !== status_success}
                >
                  <Row>
                    <Col>
                      <h3 className="mt-4">Step 2: Tell Archie who to contact</h3>

                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          if (e.nativeEvent.submitter.name === 'save') {
                            save_this_config();
                          }
                        }}
                      >
                        {renderEmailFields()}
                        <Alert show={showEmailError} className="mt-3" variant={emailAlertType}>
                          {emailAlertContent}
                        </Alert>
                        <Button
                          variant="secondary"
                          type="button"
                          onClick={() => handleTabSelect('connect')}
                          className="mt-3 px-4"
                        >
                          Back
                        </Button>

                        <Button
                          variant="primary"
                          type="submit"
                          name="save"
                          className="mt-3 px-4 right"
                          disabled={!approversEmail || !forwardEmail}
                        >
                          Save & Continue
                        </Button>
                      </form>
                    </Col>
                  </Row>
                </Tab>
                <Tab eventKey="inbox" title="Step 3: Inbox" disabled={xeroAuthStatus?.status !== status_success}>
                  <Row>
                    <Col>
                      <h3 className="mt-4">Step 3: Connect Archie to the inbox</h3>
                      <p>
                        Archie can be contacted anytime at: &nbsp;
                        <span className="eggplant-600-text">
                          <a href={`mailto:${archieEmailAddress}`}>{archieEmailAddress}</a>
                        </span>
                      </p>
                      <p>
                        For Archie to work, &nbsp;
                        <span className="eggplant-600-text">
                          <a href={`mailto:${archieEmailAddress}`}>{archieEmailAddress}</a>
                        </span>{' '}
                        needs to be added to your invoices inbox.
                      </p>
                      <Button
                        variant="secondary"
                        type="button"
                        className="px-4"
                        onClick={() =>
                          handleOpenLink('https://arcanumai.freshdesk.com/support/solutions/articles/51000417918')
                        }
                      >
                        Outlook setup FAQ
                      </Button>{' '}
                      <Button
                        variant="secondary"
                        type="button"
                        className="px-4"
                        onClick={() =>
                          handleOpenLink('https://arcanumai.freshdesk.com/support/solutions/articles/51000417916')
                        }
                      >
                        Gmail setup FAQ
                      </Button>
                      <br />
                      <br />
                      <p>
                        If you're unable to add Archie to your accounts inbox, please email{' '}
                        <a href="mailto:cs@arcanum.ai">cs@arcanum.ai</a>
                      </p>
                      <Button
                        variant="primary"
                        type="submit"
                        onClick={() => handleTabSelect('colleagues')}
                        className="mt-3 px-4"
                      >
                        Back
                      </Button>
                    </Col>
                  </Row>
                </Tab>
                <Tab
                  eventKey="subscription"
                  title="Step 4: Subscription"
                  disabled={xeroAuthStatus?.status !== status_success}
                >
                  <Row>
                    <Col>
                      <h3 className="mt-4">Step 4: Manage Subscription</h3>
                      {loadingSubscription ? (
                        <Preloader smallscreen={true} />
                      ) : xeroSubscriptionDetails ? (
                        <>
                          {subscriptionId && (
                            <Alert className="mt-3" key={subscriptionAlertType} variant={subscriptionAlertType}>
                              {subscriptionAlertContent}
                            </Alert>
                          )}
                          <br />
                          <strong>Plan:</strong> {xeroSubscriptionDetails?.subscription_details?.xero_plan_name}
                          <br />
                          <strong>Price:</strong> {xeroSubscriptionDetails?.subscription_details?.xero_item_price}
                          <br />
                          <strong>Start Date::</strong>{' '}
                          {formatTimestamp(xeroSubscriptionDetails?.subscription_details?.start_date)}
                          <br />
                          {xeroSubscriptionDetails?.subscription_details?.xero_plan_name.includes('trial') ? (
                            <>
                              <strong>End Date:</strong>{' '}
                              {formatTimestamp(xeroSubscriptionDetails?.subscription_details?.end_date)}
                              <br />
                            </>
                          ) : (
                            xeroSubscriptionDetails?.subscription_details?.end_date && (
                              <>
                                <strong>End Date:</strong>{' '}
                                {formatTimestamp(xeroSubscriptionDetails?.subscription_details?.end_date)}
                                <br />
                                Plan was canceled on:{' '}
                                {formatTimestamp(xeroSubscriptionDetails?.subscription_details?.cancellation_date)}
                                <br />
                              </>
                            )
                          )}
                          {xeroSubscriptionDetails?.subscription_details?.next_charge_date && (
                            <>
                              <strong>Next Charge Date:</strong>{' '}
                              {formatTimestamp(xeroSubscriptionDetails?.subscription_details?.next_charge_date)}
                              <br />
                            </>
                          )}
                          <strong>Status</strong> {xeroSubscriptionDetails?.subscription_details?.status}
                          <br />
                          <p>
                            Arcanum App subscription can be&nbsp;
                            <span className="eggplant-600-text">
                              <a href={xeroSubscriptionDetails.manage_subscription_url} target="_blank">
                                managed here
                              </a>
                            </span>
                          </p>
                        </>
                      ) : (
                        <>No current subscription found</>
                      )}
                    </Col>
                  </Row>
                </Tab>
              </Tabs>
              <Alert className="mt-3" key={configAlertType} variant={configAlertType}>
                {configAlertContent}
              </Alert>
            </Col>
          </Row>
        </LayoutDashboard>

        <Nav nav1on="on" nav2on="" nav3on="" {...props} />
      </div>
    </>
  );
};

export { SetupArchieMain };
