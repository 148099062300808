import React, { useEffect, useState, useRef } from 'react';
import Alert from 'react-bootstrap/Alert';
import { Breadcrumb } from '../../components/breadcrumb';
import { Container, Row, Col, Table } from 'react-bootstrap';
import { Nav } from '../../components/nav';
import { LayoutDashboard } from '../../layouts/layout-dashboard';
import { LayoutTabItems } from '../../layouts/layout-tabitems';
import { useAxios } from '../../hooks/useAxios';
import { useSessionStorage } from '../../hooks/useSessionStorage';

const MyAccount = (props) => {
  const nameRef = useRef();
  //  const companyNameRef = useRef();
  const emailRef = useRef();
  const [userID] = useSessionStorage('userId', '');

  const [alert, setAlert] = useState('d-none');
  const [role, setRole] = useState('');
  const [currency, setCurrency] = useState('');
  const [time_zone, setTimezone] = useState('');
  const [activeTab, setActiveTab] = useState('my-details');
  const { data, error, loading, fetchNow } = useAxios();

  const [isFetching, setIsFetching] = useState(true);

  const [isPuting, setIsPuting] = useState(false);
  const [isUpdatingPass, setIsUpdatingPass] = useState(false);
  const [userData, setUserData] = useState(null);
  const [putResponse, setPutResponse] = useState(null);
  const [payload, setPayload] = useState(null);
  const [oldPassword, setOldPassword] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [submitActive, setSubmitActive] = useState('disabled');
  const [apiDone, setAPIDone] = useState(false);
  const [ismatch, setIsmatch] = useState(true);
  const [passwordError, setPasswordError] = useState('');

  /**
   * changes the tab in view on click
   * @param {*} e
   * @param {*} tab
   */
  function changeTab(e, tab) {
    e.preventDefault();
    if (tab === 'my-details') window.location.reload(false);
    setPutResponse(null);
    var elements = document.getElementsByClassName('active');
    elements[0].classList.remove('active');
    e.target.parentElement.classList.add('active');
    setActiveTab(tab);
  }

  useEffect(() => {
    if (loading) return;

    if (isFetching && userID) {
      fetchNow(`/api/platform/users/${userID}/`, 'GET', null, false);
    }
    if (isPuting) {
      fetchNow(`/api/platform/users/${userID}/`, 'PUT', payload, false);
      setAPIDone(true);
    }
    if (isUpdatingPass) {
      fetchNow(`/api/platform/userChangePassword`, 'POST', payload, false);
      setAPIDone(true);
    }

    const Put = () => {
      setPutResponse(data);
      setTimeout(() => {
        setPutResponse(null); // hide the alert
      }, 5000);
      setIsPuting(false);
      setIsUpdatingPass(false);
      setAPIDone(false);
    };

    const Fetched = () => {
      setUserData(data);
      setRole(data?.Role);
      setCurrency(data?.Currency);
      setTimezone(data?.Time_zone);
      setSubmitActive('');
      if (data?.Email) {
        setIsFetching(false);
      }
    };

    if (isFetching) Fetched();
    if ((isUpdatingPass || isPuting) && data?.status && apiDone) Put();
  }, [data, loading, isFetching, isPuting, isUpdatingPass, payload, userID, apiDone, fetchNow]);

  useEffect(() => {
    if (password === '' || confirmPassword === '') return;

    if (password === confirmPassword) {
      setAlert('d-none');
      setSubmitActive('');
      setIsmatch(true);
    } else {
      setSubmitActive('disabled');
      setAlert('alert alert-secondary');
      setPasswordError('Passwords do not match.');
      setIsmatch(false);
    }
  }, [password, confirmPassword]);

  const handleDetailsSubmit = (e) => {
    e.preventDefault();
    window.localStorage.setItem('time_zone', '"' + time_zone + '"');

    if (activeTab === 'my-details') {
      if (userData?.Email === emailRef.current.value) {
        setPayload({
          name: nameRef.current.value,
          currency: currency,
          time_zone: time_zone,
          role: role,
        });
      } else {
        setPayload({
          email: emailRef.current.value,
          name: nameRef.current.value,
          currency: currency,
          time_zone: time_zone,
          role: role,
        });
      }

      setIsPuting(true);
    }
    if (activeTab === 'password-form') {
      setPayload({
        newpassword: password,
        password: oldPassword,
      });

      if (oldPassword && ismatch && confirmPassword) {
        setIsUpdatingPass(true);
      } else {
        setAlert('alert alert-secondary');
        setPasswordError('Please complete the required fields.');
      }
    }
  };

  function refreshPage() {
    window.location.reload(false);
  }

  if (error) {
    console.log('error', error);
    return null;
  }

  return (
    <>
      <div className="dashboard">
        <header>
          <Container fluid>
            <Row>
              <Col lg={8} className="px-5">
                <Breadcrumb label={'My Account'} clearStack={true} />
                <h1> My Account</h1>
              </Col>
              <Col lg={4} className="px-5">
                {/* <BetaRight /> */}
              </Col>
            </Row>
          </Container>
        </header>

        <LayoutTabItems changeTab={changeTab} tabGroup="myaccount"></LayoutTabItems>
        <LayoutDashboard>
          {putResponse ? (
            <Alert variant={putResponse.status === 'success' ? 'success' : 'warning'}>{putResponse?.message}</Alert>
          ) : null}

          <form onSubmit={handleDetailsSubmit} className="pb-5">
            {activeTab !== 'my-billing' && activeTab !== 'my-plan' && (
              <>
                <button type="submit" id="submit" className="btn btn-primary x-5 float-end" disabled={submitActive}>
                  Save
                </button>
                <span className="float-end"> &nbsp; &nbsp;</span>
                <button id="cancel" onClick={refreshPage} className="btn btn-secondary x-5 float-end">
                  Cancel
                </button>
              </>
            )}

            {activeTab === 'my-details' ? (
              <>
                <h3>Personal info</h3>
                <p className="gray">Update your personal details here</p>
                <hr className="hr" />
                <label htmlFor="name" className="label-2col">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  className="form-control input-2col"
                  placeholder="Enter your name"
                  ref={nameRef}
                  defaultValue={userData?.Name}
                />
                <hr className="hr" />
                <label htmlFor="email" className="label-2col">
                  Email
                </label>
                <input
                  type="text"
                  id="email"
                  className="form-control input-2col"
                  placeholder="Enter your email"
                  defaultValue={userData?.Email}
                  ref={emailRef}
                />

                {/* <hr className="hr" />
                <label htmlFor="currency" className="label-2col">
                  Currency
                </label>
                <select
                  name="currency"
                  className="form-select input-2col"
                  id="currency"
                  placeholder="Please select your currency"
                  value={currency}
                  onChange={(e) => setCurrency(e.target.value)}
                >
                  {currencyoptions.map((option, key) => (
                    <option key={key} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select> */}
                {/* <hr className="hr" />
                <label htmlFor="currency" className="label-2col">
                  Timezone
                </label>
                <select
                  name="timezone"
                  className="form-select input-2col"
                  id="timezone"
                  placeholder="Please select your timezone"
                  value={time_zone}
                  onChange={(e) => setTimezone(e.target.value)}
                >
                  {timezoneoptions.map((option, key) => (
                    <option key={key} value={option.label}>
                      {option.value}
                    </option>
                  ))}
                </select> */}
                <hr className="hr" />
                <label htmlFor="companyname" className="label-2col">
                  Company Name
                </label>
                <input
                  type="text"
                  className="form-control input-2col"
                  placeholder="Enter your company's name"
                  id="companyname"
                  disabled
                  //ref={companyNameRef}
                  defaultValue={userData?.Company?.name}
                />
              </>
            ) : (
              activeTab === 'password-form' && (
                <>
                  <h3>Change your password</h3>
                  <p className="gray">Here you can update your password</p>
                  <hr className="hr" />
                  <div className={alert} role="alert">
                    {passwordError}
                  </div>
                  <label htmlFor="oldPassword" className="label-2col">
                    Current Password
                  </label>
                  <input
                    type="password"
                    className="form-control confirm-password input-2col"
                    defaultValue={oldPassword}
                    id="oldPassword"
                    onChange={(e) => setOldPassword(e.target.value)}
                  />
                  <hr className="hr" />
                  <label htmlFor="password" className="label-2col">
                    New Password
                  </label>
                  <input
                    type="password"
                    className="form-control input-2col"
                    defaultValue={password}
                    id="password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <hr className="hr" />
                  <label htmlFor="confirmpassword" className="label-2col">
                    Confirm New Password
                  </label>
                  <input
                    type="password"
                    className="form-control input-2col"
                    defaultValue={confirmPassword}
                    id="confirmpassword"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </>
              )
            )}
          </form>
        </LayoutDashboard>
      </div>
      <Nav nav1on="" nav2on="" nav3on="" myaccounton="on" {...props} />
    </>
  );
};
export { MyAccount };
