import React, { useEffect, useState, useRef } from 'react';
import Alert from 'react-bootstrap/Alert';
import { Button, Form } from 'react-bootstrap';
import { LayoutForm } from '../../layouts/layout-form';
import { useNavigate } from 'react-router-dom';
import { HubspotScript } from '../../services/HubSpot';
import { XeroButton } from '../../assets/images/icons/icons';
import { XeroSignInFlow } from '../../components/xero-sign-in-flow';
import { Preloader } from '../../components/preloader';

//import roleOptions from "../../data/role-options.json";
const Register = (props) => {
  const navigate = useNavigate();

  const companyNameRef = useRef();
  const nameRef = useRef();

  const [alertType, setAlertType] = useState('');
  const [alertContent, setAlertContent] = useState('');

  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [termsChecked, setTermsChecked] = useState(false);

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordsMatched, setPasswordsMatched] = useState(false);
  const [submitActive, setSubmitActive] = useState('disabled');

  // Holds the Xero Sign up code
  const { startSigninFlow, doingXeroAuth } = XeroSignInFlow(props);

  /* This is a function that handles a form submission. 
  It checks if the terms have been checked, 
  if a role has been chosen, and if all fields have been filled out. 
  If all of these conditions are met, it runs the runProcess() function. 
  If any of these conditions are not met, it sets an alert message and type 
  to display to the user.
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    let valid = true;
    setAlertType('');
    setAlertContent('');

    if (!termsChecked) {
      valid = false;
      setAlertType('primary');
      setAlertContent('Please read and agree to the Terms and Conditions');
      return;
    }

    if (role === '') {
      valid = false;
      setAlertContent('Please choose your role');
      setAlertType('primary');
    }

    if (nameRef.current.value === '' || email === '' || companyNameRef.current.value === '') {
      valid = false;
      setAlertContent('Please fill out all fields');
      setAlertType('primary');
    }

    if (valid && passwordsMatched) {
      runProcess();
    }
  };

  const runProcess = async () => {
    const regData = {
      name: nameRef.current.value,
      username: email,
      email: email,
      role: role,
      company: companyNameRef.current.value,
      password: password,
      accept_terms: true,
    };

    if (companyNameRef.current.value === '') {
      regData.company = 'Unknown';
    }
    regData.sk = '3Ddhfy#h7839Coeorscq643m52c0w52c';

    await fetch(`/api/platform/register`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(regData),
    })
      .then((Response) => Response.json())
      .then((data) => {
        if (data.status === 'error') {
          setAlertContent(data.message);
          setAlertType('warning');
        } else {
          setAlertType('');
          navigate('/login?register=true');
        }
      })
      .catch(console.error);
  };

  useEffect(() => {
    setAlertType('');
    setAlertContent('');

    if (password === '' || confirmPassword === '') {
      setSubmitActive('disabled');
    } else if (password !== confirmPassword) {
      setPasswordsMatched(false);
      setSubmitActive('disabled');
      setAlertType('warning');
      setAlertContent('Passwords do not match.');
    } else if (password === confirmPassword) {
      setPasswordsMatched(true);
      setSubmitActive('');
    }

    //Hubspot on Login and Register - Edd 13Mar23
  }, [password, confirmPassword, role]);

  HubspotScript();

  return (
    <>
      <LayoutForm
        FormName={'register'}
        Content={
          <>
            <h1 className="mb-2">Create an account</h1>
            <h5 className="mb-4 fs-lg-1">Let's get started.</h5>
            {doingXeroAuth ? (
              <Preloader />
            ) : (
              <Button variant="primary-xero" type="submit" onClick={startSigninFlow} className="py-2">
                <img className="xero-btn" src={XeroButton} alt="Connect to Xero" /> Sign up with Xero
              </Button>
            )}
            <br />
            or
            <br />
            <Form onSubmit={handleSubmit} className="pb-4">
              <label htmlFor="name">Name</label>
              <input type="text" id="name" className="form-control mb-3" placeholder="Enter your name" ref={nameRef} />
              <label htmlFor="email">Company Email</label>
              <input
                type="email"
                id="email"
                className="form-control mb-3"
                placeholder="Enter your email"
                defaultValue={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <label htmlFor="role">Your Role</label>
              <select
                className="form-select mb-3"
                required=""
                aria-disabled="false"
                id="role"
                value={role}
                onChange={(e) => setRole(e.target.value)}
              >
                <option value="">Please choose your role</option>
                <option value="cpo">Chief Product Officer</option>
                <option value="ds">Data Scientist</option>
                <option value="mlops">Machine Learning Engineer</option>
                <option value="devops">DevOps / Cloud</option>
                <option value="dev">Software Developer</option>
                <option value="pm">Product Manager</option>
                <option value="bm">Business Manager</option>
                <option value="sm">Sales / Marketing</option>
                <option value="other">Other</option>
              </select>
              <label htmlFor="companyname">Company Name</label>
              <input
                type="text"
                className="form-control mb-3"
                placeholder="Enter your company's name"
                id="companyname"
                ref={companyNameRef}
              />
              <label htmlFor="password">Password</label>
              <input
                type="password"
                className="form-control mb-3"
                defaultValue={password}
                id="password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <label htmlFor="confirmpassword">Confirm Password</label>
              <input
                type="password"
                className="form-control mb-3"
                defaultValue={confirmPassword}
                id="confirmpassword"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <div className="mb-3">
                <input type="checkbox" id="terms_check" onChange={() => setTermsChecked(!termsChecked)} />
                <label htmlFor="terms_check">
                  &nbsp; I agree to the&nbsp;
                  <a href="https://www.arcanum.ai/terms-conditions" target="_blank" rel="noreferrer">
                    Terms and Conditions
                  </a>
                </label>
              </div>

              <Button variant="primary x-5" type="submit" disabled={submitActive}>
                Create account
              </Button>
              <Alert className="mt-3" key={alertType} variant={alertType}>
                {alertContent}
              </Alert>
              <p className="mt-4 text-center pb-4">
                Already have an account? <a href="/login">Sign in</a>
              </p>
            </Form>
          </>
        }
      ></LayoutForm>
    </>
  );
};

export { Register };
