import React from 'react';
import { Row, Col } from 'react-bootstrap';

const LayoutMonitoring = ({ children, sidebar }) => {
  return (
    <>
      <Row className="monitoring">
        <Col lg={3} className="pl-0 pe-0  eggplant-900">
          <div className="ps-3 ">{sidebar}</div>
        </Col>
        <Col lg={9} className="px-0">
          <div className="bg-white p-5">{children}</div>
        </Col>
      </Row>
    </>
  );
};

export { LayoutMonitoring };
