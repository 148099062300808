/*
Notes:
26112022 (Eddril) devmode
*/
const host = window.location.host;
let devmode = false;
let MLInstance = 'https://api-dev.arcanum.ai';
const platform_version = '2.3';

if (host.includes('localhost') || host.includes('dev') || host.includes('staging')) {
  devmode = true;
}
let envInstance = '';
let envInstanceName = '';
let optimizelySDKID = '';
let rumConfig = false;
if (host.includes('localhost')) {
  envInstance = 'http://localhost:3000';
  MLInstance = '';
  optimizelySDKID = 'NkrwCgzwZpA6zWswRpDi3';
  envInstanceName = 'dev';
} else if (host.includes('dev')) {
  envInstance = 'https://app-dev.arcanum.ai';
  MLInstance = 'https://api-dev.arcanum.ai';
  optimizelySDKID = 'NkrwCgzwZpA6zWswRpDi3';
  envInstanceName = 'dev';
  rumConfig = {
    guestRoleArn: 'arn:aws:iam::458119850496:role/accelerate_rum_unauthed_role',
    identityPoolId: 'ap-southeast-2:ac4567e5-6b61-4f89-8ba2-2d69be0972a7',
    applicationId: '666cd41a-8b1c-4603-a567-cab608203988',
  };
} else if (host.includes('staging')) {
  envInstance = 'https://app-staging.arcanum.ai';
  MLInstance = 'https://api-staging.arcanum.ai';
  optimizelySDKID = '2E6xpAXueXMJbzA1PctXo';
  envInstanceName = 'staging';
  rumConfig = {
    guestRoleArn: 'arn:aws:iam::978450690680:role/accelerate_rum_unauthed_role',
    identityPoolId: 'ap-southeast-2:4204fcab-4041-4b4a-a2f1-7aff6acae7c6',
    applicationId: 'db58fcdd-488a-41a9-a89e-bdaf1901c152',
  };
} else {
  envInstance = 'https://app.arcanum.ai';
  MLInstance = 'https://api.arcanum.ai';
  optimizelySDKID = 'D26Vfzv74XFEq6qjv1utY';
  envInstanceName = '';
  rumConfig = {
    guestRoleArn: 'arn:aws:iam::262893720581:role/accelerate_rum_unauthed_role',
    identityPoolId: 'ap-southeast-2:70f7d9f4-6ff0-40a9-a9c2-c9414c50cfeb',
    applicationId: '65c6fb7c-a3e2-4f62-9bbc-3088453fabae',
  };
}

const Console = (message, data = null, type = 'log') => {
  if (host.includes('localhost') || host.includes('dev')) {
    console[type](`${message}`, data);
  }
};

export { Console, devmode, envInstance, envInstanceName, MLInstance, platform_version, optimizelySDKID, rumConfig };
