export const getToken = async () => {
  if (tokenExpired()) {
    // get current token from local storage
    let authObj = window.localStorage.getItem('auth');
    authObj = JSON.parse(authObj);
    if (authObj === null) return false;
    const token = await GetValidTokenFromServer(authObj.refresh_token);
    if (token === false) return false;
    authObj.token = token;
    authObj.expire = Date.now() + 1000 * 20 * 1; // one minute expire
    window.localStorage.setItem('auth', JSON.stringify(authObj));
    return token;
  } else {
    // get current token from local storage
    let authObj = window.localStorage.getItem('auth');
    authObj = JSON.parse(authObj);
    return authObj.token;
  }
};

const tokenExpired = () => {
  // get current token from local storage
  try {
    let authObj = window.localStorage.getItem('auth');
    authObj = JSON.parse(authObj);
    // const diff = authObj.expire - Date.now();
    if (authObj.expire < Date.now()) {
      return true; // token expired
    } else {
      return false; // valid token
    }
  } catch (error) {
    console.log('issue with token');
  }
};

const GetValidTokenFromServer = async (refreshToken) => {
  // get new token from server with refresh token
  try {
    const request = await fetch(`/api/platform/api-jwt-token-refresh/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        refresh: refreshToken,
      }),
    });
    const response = await request.json();
    if (response.code === 'token_not_valid') {
      return false;
    }
    const token = response.access;
    return token;
  } catch (error) {
    throw new Error('Issue getting new token', error.message);
  }
};
