import { useEffect, useState } from 'react';
import { useAxios } from '../hooks/useAxios';

const GetUserId = (optimizely) => {
  const { data, error, loading, fetchNow } = useAxios();
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    if (loading) return;

    if (isFetching) {
      fetchNow(`/api/platform/userId`, 'GET', null, false);
    }

    const Fetched = () => {
      setIsFetching(false);

      if (data?.userID) {
        window.localStorage.setItem('userId', data?.userID);
        optimizely.setUser({
          id: data.userID,
          attributes: data,
        });
      }
    };

    Fetched();
  }, [data, isFetching, fetchNow, loading]);

  if (error) {
    console.log('error', error);
    return null;
  }
};

export { GetUserId };
