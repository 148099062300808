import React, { useState, useRef, useEffect } from 'react';
import { LayoutForm } from '../../layouts/layout-form';
import { useSearchParams } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';
import { useAxios } from '../../hooks/useAxios';
import axios from 'axios';
import Alert from 'react-bootstrap/Alert';
import { HubspotScript } from '../../services/HubSpot';
import { useLocation } from 'react-router-dom';
import { XeroButton } from '../../assets/images/icons/icons';
import { XeroSignInFlow } from '../../components/xero-sign-in-flow';
import { Preloader } from '../../components/preloader';

const Login = (props) => {
  const { data: userDetails = null, fetchNow: fetchUserDetails } = useAxios();
  const location = useLocation();
  const { doingXeroLogin } = location.state || {};

  const [searchParams] = useSearchParams();
  const emailRef = useRef();
  const passwordRef = useRef();
  const [alertLogin, setAlertLogin] = useState('d-none');
  const [alertType, setAlertType] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  const [loggedIn, setLoggedIn] = useState(false);
  const [alertLoginVerify, setAlertLoginVerify] = useState('d-none');
  const [alertContent, setAlertContent] = useState('');

  let verify = searchParams.get('verify');
  let email = searchParams.get('email');
  let token = searchParams.get('token');
  let register = searchParams.get('register');

  const get_userdetails_url = '/api/platform/userId';

  // Holds the Xero Sign up code
  const { startSigninFlow, subscriptionsPageUrl, doingXeroAuth, doingReferralLogin } = XeroSignInFlow(
    setAlertType,
    setShowAlert,
    setAlertContent,
  );

  useEffect(() => {
    if (doingXeroLogin) {
      setLoggedIn(true);
    }
  }, [doingXeroLogin]);

  useEffect(() => {
    if (token !== '' && verify && email !== '') {
      const doVerify = async () => {
        const payload = {
          email,
        };

        axios.interceptors.response.use(
          (response) => {
            return response;
          },
          (error) => {
            if (error.response.status === 401) {
            }
            return error;
          },
        );
        axios.interceptors.response.use(
          (response) => response,
          (error) => {
            if (error.response.status === 401) {
              error.response.headers['WWW-Authenticate'] = '';
              setAlertType('warning');
              setShowAlert(true);
              setAlertContent('Account verification failed, please try again.');
              return Promise.reject(error);
            }
            return Promise.reject(error);
          },
        );

        axios({
          method: 'post',
          url: '/api/platform/emailVerify',
          data: payload,
          headers: {
            Authorization: 'Token ' + token,
          },
        })
          .then((res) => {
            const response = res?.response?.data;
            if (response?.status === 'error') {
              setAlertType('danger');
              setAlertContent(response?.message);
              setShowAlert(true);
            } else if (response?.status === 'success') {
              setAlertType('success');
              setAlertContent('Account verified, please login.');
              setShowAlert(true);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      };
      doVerify();
    }
    if (register) {
      setAlertType('success');
      setShowAlert(true);
      setAlertContent('Account Created - Check your email to verify your account.');
    }
  }, [token, verify, email]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const loginData = {
      username: emailRef.current.value,
      password: passwordRef.current.value,
    };
    window.localStorage.removeItem('token');

    const doFetch = async () => {
      await fetch(`/api/platform/api-jwt-token/`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify(loginData),
      })
        .then((Response) => Response.json())
        .then((data) => {
          if (data.access === undefined) {
            setAlertLogin('alert alert-secondary');
          } else {
            // sessionStorage.setItem("token", data.token);
            window.localStorage.setItem('isAuth', true);
            const auth = {
              expire: Date.now() + 1000 * 60 * 5, // five minute expire
              token: data.access,
              refresh_token: data.refresh,
            };
            window.localStorage.setItem('auth', JSON.stringify(auth));

            setLoggedIn(true);
          }
        })
        .catch(console.error);
    };
    doFetch();
  };

  useEffect(() => {
    if (loggedIn) {
      if (userDetails) {
        window.localStorage.setItem('userId', userDetails?.userID);
        window.localStorage.setItem('isStaff', userDetails?.is_staff);
        props.optimizely.setUser({ id: userDetails?.userID, attributes: userDetails });
        if (userDetails.is_verify) {
          if (userDetails?.time_zone !== '') {
            window.localStorage.setItem('time_zone', '"' + userDetails?.time_zone + '"');
          }
          setAlertLoginVerify('d-none');
          window.location.href = '/solutions';
        } else {
          setLoggedIn(false);
          window.localStorage.setItem('isAuth', false);
          window.localStorage.removeItem('auth');
          window.localStorage.removeItem('userId');
          window.localStorage.removeItem('isStaff');
          props.optimizely.setUser({ id: undefined });
          setAlertLoginVerify('alert alert-secondary');
        }
      } else {
        fetchUserDetails(get_userdetails_url, 'GET', null, false);
      }
    }
  }, [userDetails, loggedIn]);

  HubspotScript();

  return doingReferralLogin ? (
    <Preloader />
  ) : (
    <>
      <LayoutForm
        FormName={'login'}
        Content={
          <>
            <h1 className="mb-2">Login</h1>
            <p className="mb-4 fs-lg-1">Welcome back! Please enter your details.</p>
            {showAlert && (
              <Alert className="mt-3" key={alertType} variant={alertType}>
                {alertContent}
                {subscriptionsPageUrl && (
                  <a href={subscriptionsPageUrl} target="_blank">
                    subscriptions page
                  </a>
                )}
              </Alert>
            )}
            {doingXeroAuth ? (
              <Preloader />
            ) : (
              <Button variant="primary-xero" type="submit" onClick={startSigninFlow} className="py-2">
                <img className="xero-btn" src={XeroButton} alt="Connect to Xero" /> Sign in with Xero
              </Button>
            )}
            <br />
            or
            <Form onSubmit={handleSubmit}>
              <label htmlFor="email">Email</label>
              <input
                id="email"
                type="email"
                name="email"
                className="form-control mb-3"
                placeholder="Enter your email"
                ref={emailRef}
              />
              <label htmlFor="password">Password</label>
              <input id="password" name="password" type="password" className="form-control mb-3" ref={passwordRef} />
              <p className="mt-4 text-center">
                <a href="/reset-password">Forgot password</a>
              </p>
              <div className={alertLogin} role="alert">
                Wrong email or password.
              </div>
              <div className={alertLoginVerify} role="alert">
                Please verify your account, then login.
              </div>
              <Button variant="primary x-5" type="submit">
                Sign in
              </Button>
            </Form>
            <p className="mt-4 text-center">
              Don't have an account? <a href="/register">Sign up</a>
            </p>
          </>
        }
      ></LayoutForm>
    </>
  );
};

export { Login };
