import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { ImgMobileDesktopWarning } from '../../assets/images/icons/icons';

const MobileDesktopWarning = () => {
  return (
    <div className="mobile-desktop-warning">
      <Container fluid className="fullscreen">
        <Row>
          <Col className="h-100vh">
            <Row className="h-100vh justify-content-center align-items-center">
              <Col md={6} className="text-center">
                <img src={ImgMobileDesktopWarning} alt="arcanum" />
                <h2 className="mt-5">Sorry, your screen size is too small to use the Arcanum Platform.</h2>
                <p className="mt-4 fs-lg-1">
                  Please switch to your laptop or desktop computer so you can start unleashing awesome!
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export { MobileDesktopWarning };
