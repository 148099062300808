import { useEffect, useState } from 'react';
import { useAxios } from '../../hooks/useAxios';

const ArchieConfig = (props) => {
  const [fetchSetting, setFetchSetting] = useState(''); // save_config get_config
  const [combination_id, setCombinationID] = useState(null);
  const { data: getConfigResp, fetchNow } = useAxios();
  const { data: postConfigResp, fetchNow: postConfig } = useAxios();
  const [tryGetConfig, setTryGetConfig] = useState(true);
  const [archie_config, setArchieConfig] = useState(null);
  const [configSet, setConfigSet] = useState(false);

  const [configAlertType, setConfigAlertType] = useState('');
  const [configAlertContent, setConfigAlertContent] = useState('');

  const [configChanged, setConfigChanged] = useState(false);

  const assistant_config_url = '/api/platform/assistant_config/';

  useEffect(() => {
    if (props?.combination_id === null) return;
    setCombinationID(props?.combination_id);
  }, [props?.combination_id]);

  useEffect(() => {
    if (postConfigResp?.status === 'error' || getConfigResp?.status === 'error') {
      setConfigChanged(false);
      setConfigAlertType('warning');
      setConfigAlertContent(postConfigResp?.message);
    }
  }, [postConfigResp, getConfigResp]);

  useEffect(() => {
    if (postConfigResp?.status === 'success' && fetchSetting === 'save_config') {
      setConfigSet(true);
      setConfigChanged(true);
      setConfigAlertType('success');
      setConfigAlertContent('Configuration Saved');

      setTimeout(() => {
        setConfigAlertType('');
        setConfigAlertContent('');
      }, 3000);
      setFetchSetting('');
    }
  }, [postConfigResp, fetchSetting]);

  useEffect(() => {
    if (getConfigResp && getConfigResp?.payload && fetchSetting === 'get_config') {
      const config_data = getConfigResp?.payload?.config;
      setArchieConfig(config_data);
      setConfigSet(true);
    }
  }, [getConfigResp, fetchSetting]);

  useEffect(() => {
    // get the config to show the user on page load
    if (!archie_config && tryGetConfig && combination_id) {
      get_config();
      setTryGetConfig(false); // we only try one time. If there is no config then nothing to do till user creates it.
    }
  }, [tryGetConfig, archie_config, combination_id]);

  const get_config = () => {
    setFetchSetting('get_config');
    fetchNow(assistant_config_url, 'GET', null, false);
  };
  const save_archie_config = (payload) => {
    setFetchSetting('save_config');
    postConfig(assistant_config_url, 'POST', payload, false);

    setConfigChanged(false);
  };

  return {
    get_config,
    archie_config,
    save_archie_config,
    fetchSetting,
    setFetchSetting,
    configAlertType,
    configAlertContent,
    configChanged,
    setConfigChanged,
    configSet,
  };
};

export { ArchieConfig };
