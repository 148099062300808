import React from 'react';
import { IconSearch } from '../assets/images/icons/icons';

const Search = ({ handleSearchInput }) => {
  return (
    <>
      <div className="search">
        <img src={IconSearch} alt="Search" />
        <input type="text" placeholder="Search" onChange={(e) => handleSearchInput(e.target.value)} />
      </div>
    </>
  );
};

export { Search };
