import React, { useState, useEffect, useRef } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Row, Col, Alert } from 'react-bootstrap';
import { Button, Form } from 'react-bootstrap';
import { LayoutForm } from '../../layouts/layout-form';
import axios from 'axios';

const ResetPassword = () => {
  const navigate = useNavigate();
  const emailRef = useRef();

  const [pageView, setPageView] = useState(false);

  const [searchParams] = useSearchParams();
  const [showError, setShowError] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [submitActive, setSubmitActive] = useState('disabled');
  const [alertReg, setAlertReg] = useState('d-none');
  const [access_valid, setAccessValid] = useState(false);
  const [checking_access, setCheckingAccess] = useState(true);

  let token = searchParams.get('token');
  const access = searchParams.get('access');
  const username = searchParams.get('username');
  if (token && token !== '') token = 'token ' + token;

  useEffect(() => {
    setShowError('');
    if (access !== null) {
      checkAccessValid();
    }
    if (token === null || access === null || username === null) {
      if (pageView !== 'received') {
        setPageView('request-form');
      }
    } else {
      if (pageView !== 'password-form') {
        setPageView('password-form');
      }
    }
  }, [token, access, username, navigate, pageView]);

  const checkAccessValid = () => {
    const doFetch = async () => {
      const payload = {
        access: access,
      };

      axios({
        method: 'post',
        url: '/api/platform/resetPassword',
        data: payload,
        headers: {
          Authorization: token,
        },
      })
        .then((res) => {
          if (res?.data.valid === true) {
            setAccessValid(true);
          } else {
            setAccessValid(false);
          }
          setCheckingAccess(false);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    doFetch();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      email: emailRef.current.value,
    };
    const doFetch = async () => {
      axios({
        method: 'post',
        url: '/api/platform/forgetPassword',
        data: payload,
      })
        .then((res) => {
          if (res?.data.status === 'success') {
            setPageView('received');
          } else if (res?.data.status === 'error') {
            setShowError(res?.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    doFetch();
  };

  const handleResetSubmit = (e) => {
    e.preventDefault();
    const payload = {
      access: access,
      password: password,
    };

    const doFetch = async () => {
      axios({
        method: 'post',
        url: '/api/platform/resetPassword',
        data: payload,
        headers: {
          Authorization: token,
        },
      })
        .then((res) => {
          if (res?.data.status === 'error') {
            setShowError(res?.data.message);
          } else {
            navigate('/login');
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    doFetch();
  };

  useEffect(() => {
    if (password === '' || confirmPassword === '') {
      ///
    } else if (password === confirmPassword) {
      setAlertReg('d-none');
      setSubmitActive('');
    } else {
      setSubmitActive('disabled');
      setAlertReg('alert alert-secondary');
    }
  }, [password, confirmPassword]);

  return (
    <>
      <LayoutForm
        FormName={'login'}
        Content={
          <>
            {showError ? (
              <Row>
                <Col>
                  <Alert variant="warning">{showError}</Alert>
                </Col>
              </Row>
            ) : null}
            {pageView === 'request-form' ? (
              <>
                <h1 className="mb-2">Request to reset your password</h1>
                <p className="mb-4 fs-lg-1">Please use this form if you have forgotten your password.</p>

                <Form onSubmit={handleSubmit}>
                  <label>Email</label>
                  <input type="text" className="form-control mb-3" placeholder="Enter your email" ref={emailRef} />

                  <Button variant="primary x-5" type="submit">
                    Request Password Reset
                  </Button>
                </Form>
                <p className="mt-4 text-center">
                  Looking for the login page?{' '}
                  <a href="/login" rel="noopener">
                    Log In
                  </a>
                </p>
              </>
            ) : pageView === 'password-form' ? (
              access_valid && !checking_access ? (
                <>
                  <h1 className="mb-2">Reset your password</h1>
                  <p className="mb-4 fs-lg-1">Please use this form to update your password.</p>
                  <Row>
                    <Col>
                      <Alert variant="warning" className={alertReg} role="alert">
                        <p> Passwords do not match.</p>
                      </Alert>
                    </Col>
                  </Row>
                  <Form onSubmit={handleResetSubmit}>
                    <label htmlFor="password">Password</label>
                    <input
                      type="password"
                      className="form-control mb-3"
                      defaultValue={password}
                      id="password"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <label htmlFor="confirmpassword">Confirm Password</label>
                    <input
                      type="password"
                      className="form-control mb-3"
                      defaultValue={confirmPassword}
                      id="confirmpassword"
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <Button variant="primary x-5" type="submit" disabled={submitActive}>
                      Update Password
                    </Button>
                  </Form>
                </>
              ) : (
                <>Password reset link expired.</>
              )
            ) : pageView === 'received' ? (
              <>
                <div className="">
                  <p className="mt-4 text-center">A request has been received to change your password</p>
                  <p className="mt-4 text-center">Please check your inbox for instructions</p>
                  <p className="mt-4 text-center">
                    Go back to the login page? <a href="/login">Log In</a>
                  </p>
                </div>
              </>
            ) : null}
          </>
        }
      ></LayoutForm>
    </>
  );
};

export { ResetPassword };
