import React, { useEffect, useState } from 'react';
import { Nav } from '../../components/nav';
import { Breadcrumb } from '../../components/breadcrumb';
import { Container, Row, Col } from 'react-bootstrap';
import { LayoutDashboard } from '../../layouts/layout-dashboard';
import { LayoutTabItems } from '../../layouts/layout-tabitems';
import Alert from 'react-bootstrap/Alert';
import { Console } from '../../services/platform-api';
import { CircleWavyWarning, HelpError, BulletTick, IconError } from '../../assets/images/icons/icons';
import { useParams } from 'react-router-dom';
import { Preloader } from '../../components/preloader';

import { AssistantConfig } from '../../data/platform/assistant-config';
import { AssistantJobDetailLogs } from '../../data/platform/assistant-jobs-detail-logs';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { jobsSummary, JobsTable, formatTimestamp } from '../../layouts/logHelpers';

const AssistantAdmin = (props) => {
  let { assistant_name, assistant_id } = useParams();
  const XERO_CONNECTED = 'connected';
  const XERO_DISCONNECTED = 'disconnected';
  const [activeTab, setActiveTab] = useState('assistant-status');

  const [approversEmail, setApproversEmail] = useState('');
  const [forwardEmail, setForwardEmail] = useState('');
  const [archieEmailAddress, setArchieEmailAddress] = useState('');

  const [emailAlertContent] = useState('');
  const [emailAlertType] = useState('');

  const [showApproversEmailError] = useState(false);
  const [showForwardEmailError] = useState(false);

  const [firstRun, setFirstRun] = useState(true);

  const {
    wf_config: config,
    wFConfigHistoryChangedAt,
    wFConfigHistoryChangedBy,
    fetchSetting,
    setFetchSetting,
    customer,
    config_loading,
  } = AssistantConfig({
    assistant_name,
    assistant_id,
  });

  const [jobLogsSummary, setJobLogsSummary] = useState(null);

  const { jobs_loading, jobLogs, fromDate, toDate, fetchJobsSetting, setFromDate, setToDate, get_logs } =
    AssistantJobDetailLogs({
      assistant_id,
    });

  useEffect(() => {
    if (jobLogs && Object.keys(jobLogs).length > 0) {
      // Calculate summary
      const job_summary = jobsSummary(jobLogs);
      setJobLogsSummary(job_summary);
    } else {
      jobsSummary({});
      setJobLogsSummary(null);
    }
  }, [jobLogs]);

  useEffect(() => {
    if (fromDate === null && toDate === null) {
      const endDate = new Date();
      const startDate = new Date();
      startDate.setDate(startDate.getDate() - 6); // Set start date to 7 days ago

      setFromDate(startDate);
      setToDate(endDate);
    }
  }, []);

  useEffect(() => {
    if (firstRun && fromDate !== null && toDate !== null) {
      setFirstRun(false);
      get_logs();
    }
  }, [firstRun, fromDate, toDate]);

  const get_logs_by = (selector) => {
    const endDate = new Date();
    const startDate = new Date();

    if (selector === 'today') {
      setFromDate(startDate);
      setToDate(startDate);
    }
    if (selector === 'yesterday') {
      startDate.setDate(startDate.getDate() - 1);
      setFromDate(startDate);
      setToDate(startDate);
    }
    if (selector === 'this_week') {
      startDate.setDate(startDate.getDate() - 6); // Set start date to 7 days ago
      setFromDate(startDate);
      setToDate(endDate);
    }

    get_logs(startDate, endDate);
  };

  useEffect(() => {
    if (config && fetchSetting === 'get_config') {
      try {
        setApproversEmail(config['email_address_approver']);
        setForwardEmail(config['email_address_forward']);
        setArchieEmailAddress(config['email_address_archie']);
      } catch (error) {
        Console('issue getting all fields');
      }

      setFetchSetting('');
    }
  }, [, config, fetchSetting]);

  const emailFields = {
    email_address_approver: {
      label: 'Supplier Approver Email',
      desc: 'When Archie receives an invoice from a new supplier, he should request approval from:',
      value: approversEmail,
      showError: showApproversEmailError,
      setter: setApproversEmail,
    },
    email_address_forward: {
      label: 'Forward Email',
      desc: 'When Archie can’t take care of an email in the inbox, he should forward it to:',
      value: forwardEmail,
      showError: showForwardEmailError,
      setter: setForwardEmail,
    },
    email_address_archie: {
      label: 'Archies Email',
      desc: 'The inbox to send invoices to:',
      value: archieEmailAddress,
      showError: showForwardEmailError,
      setter: setArchieEmailAddress,
    },
  };

  /**
   * changes the tab in view on click
   */
  function changeTab(e, tab) {
    e.preventDefault();
    var elements = document.getElementsByClassName('active');
    elements[0].classList.remove('active');
    e.target.parentElement.classList.add('active');
    setActiveTab(tab);
  }

  function NotFoundElement() {
    return (
      <LayoutDashboard>
        <h2>Sorry nothing matched your search</h2>
      </LayoutDashboard>
    );
  }

  const renderEmailFields = () => {
    return Object.entries(emailFields).map(([key, field]) => (
      <div key={key}>
        <label htmlFor={key} className="label-2col">
          {field.label}
        </label>

        <input
          type="text"
          id={key}
          disabled
          value={field.value}
          onChange={(e) => handleEmailUpdate(key, e.target.value)}
          placeholder="email@domain.com"
          className="form-control input-2col"
        />
        <div className="tooltip">
          <img src={HelpError} className="tooltip_icon" alt="help" />
          <span className="tooltiptext">{field.desc}</span>
        </div>

        <br />
        <Alert show={field.showError} className="mt-3" variant={emailAlertType}>
          {emailAlertContent}
        </Alert>
        <br />
      </div>
    ));
  };

  if (assistant_name !== 'Archie') {
    return (
      <LayoutDashboard>
        <h2>Only supporting Archie at this time.</h2>
      </LayoutDashboard>
    );
  }
  return (
    <>
      <div className="dashboard">
        <header>
          <Container fluid>
            <Row>
              <Col lg={8} className="px-5">
                <Breadcrumb label={'Assistant'} />
                <h1>
                  {assistant_name} for {customer?.company?.name}
                </h1>
              </Col>
            </Row>
          </Container>
        </header>

        <LayoutTabItems changeTab={changeTab} tabGroup="assistant"></LayoutTabItems>

        <LayoutDashboard>
          <div className="right" style={{ marginTop: '-91px' }}>
            <button className="btn btn-secondary relative" onClick={() => get_logs_by('today')}>
              Today
            </button>
            &nbsp; &nbsp;
            <button className="btn btn-secondary relative" onClick={() => get_logs_by('yesterday')}>
              Yesterday
            </button>
            &nbsp; &nbsp;
            <button className="btn btn-secondary relative" onClick={() => get_logs_by('this_week')}>
              This week
            </button>
            &nbsp; &nbsp; <span className="relative">|</span> &nbsp; &nbsp;
            <label className="relative">From:</label>
            <DatePicker
              className="form-control "
              selected={fromDate}
              onChange={(date) => setFromDate(date)}
              dateFormat="dd-MM-yyyy"
            />
            <label className="relative">To:</label>
            <DatePicker
              className="form-control "
              selected={toDate}
              onChange={(date) => setToDate(date)}
              dateFormat="dd-MM-yyyy"
            />
            <button disabled={!fromDate || !toDate} className="btn btn-primary relative" onClick={() => get_logs()}>
              Go
            </button>
          </div>

          {activeTab === 'assistant-status' ? (
            <>
              {/* KEY STATUS TAB CONTENT */}

              <Row className="flexcenter subsearch ms-0">
                <Col lg={5} md={12}>
                  <h3>Jobs Summary</h3>
                  {jobs_loading ? (
                    <Preloader smallscreen={true} />
                  ) : jobLogsSummary ? (
                    <>
                      <p>Last email seen {jobLogsSummary?.lastEmailSeen?.toLocaleString()}</p>
                      <Row className="mt-5">
                        <Col md={4} className="text-center">
                          <p>Total emails seen </p>
                          <span className="assistant_summary_card ">{jobLogsSummary?.totalEmailsSeen}</span>
                        </Col>
                        <Col md={4} className="text-center">
                          <p>Total invoices detected </p>
                          <span className="assistant_summary_card ">{jobLogsSummary?.totalInvoicesDetected}</span>
                        </Col>
                        <Col md={4} className="text-center">
                          <p>Total draft bills created </p>
                          <span className="assistant_summary_card ">{jobLogsSummary?.totalDraftBillsCreated}</span>
                        </Col>

                        <Col md={4} className="text-center"></Col>
                        <Col md={4} className="text-center">
                          <br />
                          <br />
                          <p>Invoice extraction rate </p>
                          <span className="assistant_summary_card large">{jobLogsSummary?.extractionRate}%</span>
                        </Col>
                        <Col md={4} className="text-center">
                          <br />
                          <br />
                          <p>Draft bill creation rate </p>
                          <span className="assistant_summary_card large">{jobLogsSummary?.billCreationRate}%</span>
                        </Col>
                      </Row>
                    </>
                  ) : fetchJobsSetting !== '' ? (
                    <NotFoundElement />
                  ) : null}
                </Col>

                <Col lg={7} md={12} className="ps-5">
                  <h3>Account Configurations</h3>
                  {customer !== null ? (
                    <>
                      <label htmlFor="accelerate-email" className="label-2col">
                        Arcanum Login Email
                      </label>
                      <input
                        type="text"
                        id="accelerate-email"
                        disabled
                        value={customer?.user?.email}
                        placeholder="email@domain.com"
                        className="form-control input-2col"
                      />
                      <br /> <br /> <br />
                      {renderEmailFields()}
                      Last updated by: {wFConfigHistoryChangedBy} on {wFConfigHistoryChangedAt}
                    </>
                  ) : config_loading ? (
                    <Preloader smallscreen={true} />
                  ) : (
                    <NotFoundElement />
                  )}
                </Col>
              </Row>

              <hr />
              <Row className="flexcenter subsearch ms-0">
                <Col>
                  <Row>
                    <h3>Connection Status</h3>
                    {config_loading ? (
                      <Preloader smallscreen={true} />
                    ) : config && config?.xero && Object.keys(config?.xero).length > 0 ? (
                      <Col lg={3} md={6} className="text-center">
                        <div
                          className="assistant_summary_card"
                          style={{
                            color: config?.xero?.connection_status === XERO_CONNECTED ? 'green' : 'red',
                          }}
                        >
                          <strong>Xero</strong>
                          <br />
                          {config?.xero?.connection_status === XERO_CONNECTED ? (
                            <div>
                              connected <img src={BulletTick} className="tooltip_icon" alt="help" />
                            </div>
                          ) : config?.xero?.connection_status === XERO_DISCONNECTED ? (
                            <div>
                              not connected <img src={IconError} className="tooltip_icon" alt="help" />
                            </div>
                          ) : (
                            <div>
                              <img src={CircleWavyWarning} className="tooltip_icon" alt="help" />
                            </div>
                          )}
                        </div>
                        <br />
                        {config?.xero?.connection_status === XERO_CONNECTED ? (
                          <>
                            {config?.xero?.tenant_name}
                            <br />
                            authorised at: {formatTimestamp(config?.xero?.created_at)}
                          </>
                        ) : (
                          <>Last update: {formatTimestamp(config?.xero?.updated_at)}</>
                        )}
                      </Col>
                    ) : (
                      <>No connection setup at this time</>
                    )}
                  </Row>
                </Col>
              </Row>
            </>
          ) : activeTab === 'assistant-job-details' ? (
            <>
              {/* JOB DETAILS CONTENT */}

              <Row className="flexcenter subsearch ms-0">
                <Col>
                  <h3 className="card-header">Job Log Details</h3>

                  {jobLogs && Object.keys(jobLogs).length > 0 ? (
                    <JobsTable jobLogs={jobLogs} />
                  ) : jobs_loading ? (
                    <Preloader smallscreen={true} />
                  ) : fetchJobsSetting !== '' ? (
                    <NotFoundElement />
                  ) : null}
                </Col>
              </Row>
            </>
          ) : null}
        </LayoutDashboard>
        <Nav navportalon="on" nav1on="" nav2on="" nav3on="" {...props} />
      </div>
    </>
  );
};

export { AssistantAdmin };
