import { useEffect, useState } from 'react';
import { useAxios } from '../../hooks/useAxios';

const AssistantJobDetailLogs = (props) => {
  const assistant_id = props?.assistant_id;
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [fetchJobsSetting, setFetchSetting] = useState('');
  const [jobLogs, setJobLogs] = useState({});

  const { loading: jobs_loading, data: JobLogsData, fetchNow: getJobsDetails, resetData } = useAxios();

  useEffect(() => {
    if (fetchJobsSetting !== 'get_logs' || jobs_loading) return;
    if (JobLogsData && JobLogsData.status === 'success' && JobLogsData.logs.length > 0) {
      const normalizedLogs = normalizeLogs(JobLogsData.logs);

      const groupedData = normalizedLogs.reduce((acc, item) => {
        const baseJobFlowID = item.JobFlowID.replace(/-\d+$/, '');

        if (item.JobFlowID.startsWith('j-')) {
          if (!acc[baseJobFlowID]) {
            acc[baseJobFlowID] = { baseLogs: [], subLogs: {} };
          }

          if (item.JobFlowID === baseJobFlowID) {
            if (!acc[baseJobFlowID].baseLogs[item.JobFlowID]) {
              acc[baseJobFlowID].baseLogs[item.JobFlowID] = [];
            }
            acc[baseJobFlowID].baseLogs[item.JobFlowID].push(item);
          } else {
            if (!acc[baseJobFlowID].subLogs[item.JobFlowID]) {
              acc[baseJobFlowID].subLogs[item.JobFlowID] = [];
            }
            acc[baseJobFlowID].subLogs[item.JobFlowID].push(item);
          }
        }

        return acc;
      }, {});

      setJobLogs(groupedData);
      console.log('formatted jobs: ', groupedData);
      setFetchSetting('got_logs');
    }
  }, [JobLogsData, jobs_loading, fetchJobsSetting]);

  function normalizeLogs(logs) {
    return logs.map((log) => {
      // Rename 'event' to 'message' if it exists
      if (log.event) {
        log.message = log.event;
        delete log.event;
      }

      // Ensure the log has a JobFlowID, prioritize job_flow_id if present
      if (log.job_flow_id) {
        log.JobFlowID = log.job_flow_id;
        delete log.job_flow_id;
      } else if (!log.JobFlowID) {
        log.JobFlowID = ''; // Ensure JobFlowID is always defined
      }

      return log;
    });
  }

  const get_logs = (start_override, end_override) => {
    resetData();
    setJobLogs(null);
    if (!start_override) {
      start_override = fromDate;
    }
    if (!end_override) {
      end_override = toDate;
    }

    getJobsDetails(
      `/api/platform/assistant_job_logs/${assistant_id}/${formatDate(start_override)}/${formatDate(end_override)}`,
      'GET',
      null,
      false,
    );
    setFetchSetting('get_logs');
  };

  const formatDate = (date) => {
    return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${date.getFullYear()}`;
  };

  return { jobs_loading, jobLogs, fromDate, toDate, fetchJobsSetting, setFromDate, setToDate, get_logs };
};
export { AssistantJobDetailLogs };
