import React, { useEffect, useState } from 'react';

import { Container, Row, Col } from 'react-bootstrap';
import boost_img from '../assets/images/boost.png';
import dashboard_img from '../assets/images/dashboard.png';
import integrate_img from '../assets/images/integrate.png';
import user_img from '../assets/images/user-friendly.png';

const PlatfromBenefits = () => {
  const [index, setIndex] = useState(0);
  const benefits = document.querySelectorAll('.benefits_trial ul li');

  function showBenefit() {
    benefits.forEach((benefit, i) => {
      if (i === index % benefits.length) {
        benefit.classList.add('active');
      } else {
        benefit.classList.remove('active');
      }
    });
    setIndex((index) => index + 1);
  }

  useEffect(() => {
    const interval = setInterval(showBenefit, 6000);
    return () => clearInterval(interval);
  }, [index]);

  return (
    <>
      <Row className="benefits_trial">
        <Col lg={12}>
          <ul>
            <li className="active">
              <h3>Easily Integrate</h3>
              <br />
              <p>Connect directly to your currently used software & applications such as Xero and Outlook</p>

              <img src={integrate_img} />
            </li>

            <li>
              <h3>Boost Productivity</h3>
              <br />
              <p>Add 50% more capacity to your finance team with Archie’s AI and Automation</p>

              <img src={boost_img} />
            </li>

            <li>
              <h3> Personal Dashboard</h3>
              <br />
              <p>Get a clear overview of currently running tasks along with personalized recommendations</p>
              <img src={dashboard_img} />
            </li>

            <li>
              <h3>User friendly</h3>
              <br />
              <p>Designed for ease of use, with no coding skills needed</p>

              <img src={user_img} />
            </li>
          </ul>
        </Col>
      </Row>
    </>
  );
};

export default PlatfromBenefits;
