import React from 'react';
import { LayoutFullscreen } from '../../layouts/layout-fullscreen';

const NoMatch = () => {
  return (
    <>
      <LayoutFullscreen>
        <h1>404</h1>
        <h4>Page not found</h4>
        <a href="/solutions" className="btn btn-light mt-3" rel="noopener">
          Back to Home
        </a>
      </LayoutFullscreen>
    </>
  );
};

export { NoMatch };
