import React from 'react';
import LogoBk from '../assets/images/logo-arcanum-bk.svg';
import { Container, Row, Col } from 'react-bootstrap';

const LayoutFullscreen = ({ children }) => {
  return (
    <>
      <Container fluid className="fullscreen">
        <header>
          <img src={LogoBk} className="logo-bk" alt="Arcanum" />
        </header>
        <Row>
          <Col className="h-100vh">
            <Row className="h-100vh justify-content-center align-items-center">
              <Col lg={6} className="text-center">
                {children}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export { LayoutFullscreen };
