import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../../assets/css/welcome.scss';
import LogoBk from '../../assets/images/logo-arcanum-bk.svg';
import Img3 from '../../assets/images/img-accelerate-screenshot.png';

const Teaser2 = () => {
  return (
    <>
      <Container fluid className="fullscreen">
        <header>
          <a href="/" rel="noopener">
            <img src={LogoBk} height="55" className="logo-bk" alt="Arcanum" />
          </a>
        </header>
        <Row style={{ marginTop: '80px' }}>
          <Col lg={6} className="bgGray d-flex align-items-center">
            <div>
              <h1 className="mb-4">AI assistants to support growing businesses</h1>
              <h3 className="mb-5">
                Discover our workforce of AI assistants designed to help you automate key back office functions
                end-to-end. Arcanum enables you to securely integrate AI powered assistants into your current systems
                and softwares, in order to support business growth.
              </h3>
              <a href="/register" className="btn btn-primary btn-lg px-5" rel="noopener">
                Start now
              </a>
            </div>
          </Col>
          <Col lg={6} className="bgGray">
            <img src={Img3} alt="Arcanum Platform" width="100%" className="mt-4" />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export { Teaser2 };
