import React from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../assets/images/logo-accelerate.svg';
import { Nav1, Nav2, /*Nav3,*/ NavAccount } from '../assets/images/icons/icons';
import { GetUserId } from '../services/platform-userid';
import { HubspotScript } from '../services/HubSpot';
import { useSessionStorage } from '../hooks/useSessionStorage';

const Nav = ({ optimizely, navportalon, nav1on, myaccounton }) => {
  const navigate = useNavigate();
  GetUserId(optimizely);
  HubspotScript();

  const [isStaff] = useSessionStorage('isStaff', false);

  const logout = () => {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('isAuth', false);
    window.localStorage.removeItem('auth');
    window.localStorage.removeItem('userId');
    window.localStorage.removeItem('time_zone');
    window.localStorage.removeItem('shouldCollapseNews');
    window.localStorage.removeItem('isStaff');
    window.sessionStorage.removeItem('navigation_stack');

    optimizely?.setUser({ id: undefined });

    navigate('/login');
    window.location.reload(false);
  };

  return (
    <>
      <nav>
        <a href="/home" rel="noopener">
          <img src={Logo} className="logo-bk" alt="Arcanum" />
        </a>

        <ul>
          {isStaff ? (
            <li>
              <a href="/portal" id="nav_portal" className={'nav-btn ' + navportalon} rel="noopener">
                Admin
                <br />
                Portal
              </a>
            </li>
          ) : null}

          <li>
            <a href="/solutions" id="nav_solutions" className={nav1on} rel="noopener">
              <img src={Nav1} alt="Arcanum" />
            </a>
          </li>
          {/* <li className="active_assistant ai_assistant">
            <a href="/solution/1/Finance" id="nav_archie" rel="noopener">
              <img src={ArchieImg} title="Archie" alt="Archie" width="50" />
            </a>
          </li>
          <li className="ai_assistant">
            <a href="/solutions" id="nav_arcos" rel="noopener">
              <img src={ArcosImg} title="Arcos" alt="Arcos" width="50" />
            </a>
          </li>
          <li className="ai_assistant">
            <a href="/solutions" id="nav_arcos" rel="noopener">
              <img src={ArcelImg} title="Arcela" alt="Arcela" width="50" />
            </a>
          </li>
          <li className="ai_assistant">
            <a href="/solutions" id="nav_arcos" rel="noopener">
              <img src={ArchanaImg} title="Archana" alt="Archana" width="50" />
            </a>
          </li> */}
          {/* <li>
            <a href="/solutions" className={nav2on} rel="noopener">
              <img src={Nav2} alt="Arcanum" />
            </a>
          </li> */}
          {/* <li>
            <a href="/solutions" className={nav3on} rel="noopener">
              <img src={Nav3} alt="Arcanum" />
            </a>
          </li> */}
        </ul>
        <footer>
          <a href="/my-account" className={'btn-account ' + myaccounton} rel="noopener">
            <img src={NavAccount} alt="My Account" />
          </a>
          <button onClick={logout} className="btn-logout">
            Log out
          </button>
          <span className="platform_version">v2.4</span>
        </footer>
      </nav>

      {/* <Intercom /> */}
    </>
  );
};

export { Nav };
