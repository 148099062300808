import { Login } from '../pages/common/login';
import { Register } from '../pages/common/register';
import { NoMatch } from '../pages/common/nomatch';
import { Solutions } from '../pages/dashboard/solutions';

import { MyAccount } from '../pages/common/myAccount';
import { ResetPassword } from '../pages/common/resetPassword';

import { UserMonitoring } from '../pages/common/userMonitoring';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { MobileDesktopWarning } from '../pages/common/desktopOptimised';
import { Teaser2 } from '../pages/common/welcome-to-arcanuma';
// Assistant Based
import { Portal } from '../pages/dashboard/portal';
import { AssistantAdmin } from '../pages/dashboard/assistant-admin';
import { SetupArchieMain } from '../pages/dashboard/setup-archie-main';

const AccelerateRoutes = (props) => {
  const auth = window.localStorage.getItem('isAuth');
  const isStaff = window.localStorage.getItem('isStaff');
  const windowSize = window.innerWidth;
  const isMobileSize = windowSize < 992;

  return (
    <Router>
      {!isMobileSize ? (
        <Routes>
          <Route
            exact
            path="/portal"
            element={
              auth === 'true' ? (
                isStaff === 'true' ? (
                  <Portal {...props} />
                ) : (
                  <Navigate to="/home" replace />
                )
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            exact
            path="/assistant-admin/:assistant_name/:assistant_id"
            element={
              auth === 'true' ? (
                isStaff === 'true' ? (
                  <AssistantAdmin {...props} />
                ) : (
                  <Navigate to="/home" replace />
                )
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />

          <Route
            exact
            path="/setup-archie"
            element={auth === 'true' ? <SetupArchieMain {...props} /> : <Navigate to="/login" replace />}
          />
          <Route
            exact
            path="/solutions"
            element={auth === 'true' ? <Solutions {...props} /> : <Navigate to="/login" replace />}
          />

          <Route
            exact
            path="/monitoring/:id"
            element={auth === 'true' ? <UserMonitoring {...props} /> : <Navigate to="/login" replace />}
          />
          <Route
            exact
            path="/my-account"
            element={auth === 'true' ? <MyAccount {...props} /> : <Navigate to="/login" replace />}
          />
          <Route
            exact
            path="/reset-password"
            element={
              auth === null || auth === 'false' ? <ResetPassword {...props} /> : <Navigate to="/solutions" replace />
            }
          />

          <Route path="*" element={auth === 'true' ? <NoMatch {...props} /> : <Navigate to="/login" replace />} />
          <Route path="/" element={auth === 'true' ? <Solutions {...props} /> : <Navigate to="/login" replace />} />
          <Route exact path="/login" element={<Login {...props} />} />
          <Route exact path="/xero-referral" element={<Login {...props} />} />
          <Route exact path="/register" element={<Register {...props} />} />
          <Route exact path="/home" element={<Solutions {...props} />} />
          <Route exact path="/welcome" element={<Teaser2 {...props} />} />
        </Routes>
      ) : (
        <Routes>
          <Route path="*" element={<MobileDesktopWarning />} />
        </Routes>
      )}
    </Router>
  );
};

export { AccelerateRoutes };
