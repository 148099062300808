import { envInstanceName } from './platform-api';

const HubspotScript = () => {
  if (envInstanceName !== 'dev' && envInstanceName !== 'staging') {
    //if (envInstanceName === 'dev') {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = 'hs-script-loader';
    script.async = true;
    script.defer = true;
    script.src = '//js.hs-scripts.com/5849431.js';
    head.appendChild(script);

    return () => {
      head.removeChild(script);
    };
  }
};

export { HubspotScript };
