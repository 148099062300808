import React, { Fragment, useEffect, useState } from 'react';
import moment from 'moment';
import { LayoutDashboard } from '../../layouts/layout-dashboard';
import { Nav } from '../../components/nav';
import { Breadcrumb } from '../../components/breadcrumb';
import { Search } from '../../components/search';
import { Container, Row, Col } from 'react-bootstrap';
import { useAxios } from '../../hooks/useAxios';
import { Preloader } from '../../components/preloader';

const Portal = (props) => {
  const { loading, data = null, error, fetchNow } = useAxios();
  const [customerData, setCustomerData] = useState([]);
  let getCustomerDataURL = `/api/platform/customers/`;

  const [filterText, setFilterText] = useState('');
  const [search, setSearch] = useState(false);
  const [searching, setSearching] = useState(false);
  const [resetSearch, setResetSearch] = useState(false);

  // pagination
  const [totalItemsCount, setTotalItemCount] = useState(false);
  const [currentPage, setCurrentPage] = useState(false);
  const [prevPage, setPrevPage] = useState(false);
  const [nextPage, setNextPage] = useState(false);

  let timeOutId = null;

  /**
   * Fetches and updates customer data based on the current state.
   */
  useEffect(() => {
    if (loading) return;

    if (filterText === '' && !data) {
      fetchNow(getCustomerDataURL, 'GET', null, false);
      return;
    }

    if (data) {
      setPrevPage(data.previous ? new URL(data.previous).pathname + new URL(data.previous).search : null);
      setNextPage(data.next ? new URL(data.next).pathname + new URL(data.next).search : null);
      setCurrentPage(data.results.current_page || null);
      setTotalItemCount(data.results.total_pages || null);

      if (data.results.customers && data.status === 'success') {
        setCustomerData(data.results.customers);
      } else if (search) {
        setCustomerData(data.length === 0 ? { ...customerData, customer: undefined } : customerData);
      }

      setSearch(false);
      setSearching(false);
    }
  }, [data, fetchNow, loading, customerData, filterText, search]);

  /**
   * Effect that fetches customer data from an API endpoint based on the `filterText` state.
   * Also fetches all customer data when the `resetSearch` state is true.
   */
  useEffect(() => {
    if (!search) return;
    if (filterText.length > 2 && filterText !== '' && searching) {
      fetchNow(getCustomerDataURL + '?q=' + filterText, 'GET', null, false);
    } else if (resetSearch) {
      // grab all again
      fetchNow(getCustomerDataURL, 'GET', null, false);
      setResetSearch(false);
    }
  }, [filterText, fetchNow, getCustomerDataURL, resetSearch, search, searching]);

  const handleSearchChange = (searchString) => {
    clearTimeout(timeOutId);
    timeOutId = setTimeout(() => {
      setSearch(true);
      setSearching(true);
      setFilterText(searchString);
    }, 700);
    if (searchString === '') setResetSearch(true);
  };

  function NotFoundElement() {
    return (
      <LayoutDashboard>
        <h2>Sorry nothing matched your search</h2>
      </LayoutDashboard>
    );
  }

  if (error) {
    console.log('error', error);
    return null;
  }

  return (
    <>
      <div className="dashboard">
        <header>
          <Container fluid>
            <Row>
              <Col lg={8} className="px-5">
                <Breadcrumb label={'Admin Portal'} clearStack={true} />
                <h1>Admin Portal</h1>
              </Col>
              <Col lg={4} className="px-5">
                {/* <BetaRight /> */}
              </Col>
            </Row>
          </Container>
        </header>

        <LayoutDashboard>
          <Row className="flexcenter subsearch ms-0">
            <label className="ps-0">Search by Customer</label>
            <Search handleSearchInput={handleSearchChange} />
          </Row>
          {customerData.length > 0 ? (
            <>
              <table id="customer-table">
                <thead>
                  <tr>
                    <th className="label">Customer Name</th>
                    <th>Partner Name</th>
                    <th>Company Id</th>
                    <th>User Info</th>
                    <th>Acct Creation Date</th>
                    <th>Go to Assistant(s)</th>
                  </tr>
                </thead>
                <tbody>
                  {customerData.map((customer, key) => (
                    <tr key={key}>
                      <td>{customer.company_name}</td>
                      <td>none</td>
                      <td>{customer.company_id}</td>
                      <td>
                        {customer.name} <br />
                        {customer.email}
                      </td>
                      <td>{moment(customer.company_creation_date).format('Do MMMM YYYY')}</td>
                      <td>
                        {customer?.assistants
                          ? customer?.assistants.map((assistant, assistant_key) => {
                              // Skip assistants with type 'arcos'
                              if (assistant?.assistant_type === 'Arcos') {
                                return null;
                              }
                              return (
                                <Fragment key={assistant_key}>
                                  <button
                                    className="btn btn-primary"
                                    onClick={() => {
                                      window.location.href = `/assistant-admin/${assistant?.assistant_type}/${assistant.id}`;
                                    }}
                                  >
                                    {assistant?.assistant_type}
                                  </button>{' '}
                                  &nbsp;
                                </Fragment>
                              );
                            })
                          : none}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="pagination-container">
                <div className="pagination">
                  {prevPage && (
                    <button className="btn btn-primary" onClick={() => fetchNow(prevPage, 'GET', null, false)}>
                      Previous
                    </button>
                  )}
                  Page {currentPage} of {totalItemsCount}
                  {nextPage && (
                    <button className="btn btn-primary" onClick={() => fetchNow(nextPage, 'GET', null, false)}>
                      Next
                    </button>
                  )}
                </div>
              </div>
              {loading ? <Preloader /> : null}
            </>
          ) : loading ? (
            <Preloader smallscreen={true} />
          ) : data !== null ? (
            <NotFoundElement />
          ) : null}
        </LayoutDashboard>

        <Nav navportalon="on" nav1on="" nav2on="" nav3on="" {...props} />
      </div>
    </>
  );
};

export { Portal };
