import { useState, useCallback } from 'react';
import axios from 'axios';
import { getToken } from './token';
import { useNavigate } from 'react-router-dom';

export function useAxios() {
  const navigate = useNavigate();
  const [status, setStatus] = useState({
    data: null,
    error: null,
    loading: false,
  });

  const resetData = () => {
    setStatus({ loading: false, data: null, error: null });
  };

  const fetchNow = useCallback(
    async (url, type, payload, headers_override = false, isfile = false) => {
      let token;
      if (!headers_override) {
        try {
          token = await getToken();
          if (!token) {
            window.localStorage.removeItem('token');
            window.localStorage.setItem('isAuth', false);
            window.localStorage.removeItem('auth');
            window.localStorage.removeItem('userId');
            navigate('/login');
            window.location.reload(false);
          }
        } catch (err) {
          navigate('/login');
          window.location.reload(false);
        }
      }

      let headers = {};

      if (isfile) {
        headers['Content-Type'] = 'multipart/form-data';
      } else {
        headers['Content-Type'] = 'application/json';
      }

      // Add authorization if a token is present and not overridden
      if (token && !headers_override?.Authorization) {
        headers['Authorization'] = 'Bearer ' + token;
      }

      // Override headers if provided
      headers = { ...headers, ...headers_override };
      setStatus({ loading: true, data: null, error: null });
      try {
        const response = await axios({
          method: type,
          url: url,
          data: payload,
          headers,
        });
        if (response.data.status_code === 401) {
          navigate('/login');
          window.location.reload(false);
        } else {
          setStatus({ loading: false, error: null, data: response.data });
        }
      } catch (err) {
        setStatus({ loading: false, error: err, data: null });
      }
    },
    [navigate],
  );

  return { ...status, fetchNow, resetData };
}
