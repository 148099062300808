import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import './assets/css/accelerate.scss';
import { AccelerateRoutes } from './routes/routes';
import { OptimizelyProvider, createInstance } from '@optimizely/react-sdk';
import { optimizelySDKID, rumConfig, platform_version } from './services/platform-api.js';
import { AwsRum } from 'aws-rum-web';

function App() {
  const optimizely = createInstance({
    sdkKey: optimizelySDKID,
    logLevel: 'ERROR',
  });

  if (rumConfig)
    new AwsRum(rumConfig.applicationId, platform_version, 'ap-southeast-2', {
      sessionSampleRate: 1,
      guestRoleArn: rumConfig.guestRoleArn,
      identityPoolId: rumConfig.identityPoolId,
      endpoint: 'https://dataplane.rum.ap-southeast-2.amazonaws.com',
      telemetries: ['errors', 'performance', 'http'],
      allowCookies: true,
      enableXRay: true,
    });

  return (
    <OptimizelyProvider optimizely={optimizely}>
      <React.StrictMode>
        <AccelerateRoutes optimizely={optimizely} />
      </React.StrictMode>
    </OptimizelyProvider>
  );
}

export default App;
