import React, { useEffect, useState } from 'react';
import { Breadcrumb } from '../../components/breadcrumb';
import { Row, Col, Container, Tabs, Tab } from 'react-bootstrap';
import { Nav } from '../../components/nav';
import { LayoutMonitoring } from '../../layouts/layout-monitoring';
import { useAxios } from '../../hooks/useAxios';
import { Console } from '../../services/platform-api';
import { useParams } from 'react-router-dom';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

const dummy_data = [
  {
    Id: '1',
    Cilent_id: '4003',
    Data_source: 'ML_Training_Only_State_Machine',
    Type: [
      {
        type: 'FAILED',
        value: '747.000000',
      },
      {
        type: 'SUCCEEDED',
        value: '65046.000000',
      },
      {
        type: 'success_rate',
        value: '0.988646',
      },
      {
        type: 'time_cost_unit_min',
        value: '16.000000',
      },
    ],
    Date: '2022-12-29',
  },
  {
    Id: '2',
    Cilent_id: '4003',
    Data_source: 'ML_Training_Only_State_Machine',
    Type: [
      {
        type: 'FAILED',
        value: '557.000000',
      },
      {
        type: 'SUCCEEDED',
        value: '34046.000000',
      },
      {
        type: 'success_rate',
        value: '0.958646',
      },
      {
        type: 'time_cost_unit_min',
        value: '13.000000',
      },
    ],
    Date: '2022-12-30',
  },
  {
    Id: '3',
    Cilent_id: '4003',
    Data_source: 'ML_Training_Only_State_Machine',
    Type: [
      {
        type: 'FAILED',
        value: '947.000000',
      },
      {
        type: 'SUCCEEDED',
        value: '87046.000000',
      },
      {
        type: 'success_rate',
        value: '0.348646',
      },
      {
        type: 'time_cost_unit_min',
        value: '19.000000',
      },
    ],
    Date: '2022-12-31',
  },
];

const UserMonitoring = (props) => {
  const { data, error, loading, fetchNow } = useAxios();
  const [solutionsData, setSolutionsData] = useState([]);

  const [graphData, setGraphData] = useState(null);
  const [graphOptions, setGraphOptions] = useState('');
  const [isFetching, setIsFetching] = useState(true);
  const [step, setStep] = useState(1);

  // const [activeTab, setActiveTab] = useState("my-details");
  const [WorkflowactiveTab, setWorkflowactiveTab] = useState('');
  const { id } = useParams();

  ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend);

  useEffect(() => {
    if (loading) return;

    if (step === 1) {
      if (isFetching) {
        Console('fetching tab items - solutions', '', 'log');
        fetchNow(`/api/platform/solution/${id}/`, 'GET', null, false);
        setIsFetching(false);
      } else {
        if (data?.workflows && data !== null) {
          Console('done, we have data - solutions ', data, 'info');
          setSolutionsData(data?.workflows);
          setIsFetching(true);
          setStep(2);
        }
      }
    }
    if (step === 2) {
      if (isFetching) {
        Console('fetching monitoring data', '', 'log');

        // /api/platform/monitoringData?client_id=4003&datasource=ML_Training_Only_State_Machine&period=2
        // /api/platform/monitoringData?client_id=3562&datasource=Monitoring-Test/randomint&period=1
        fetchNow(
          `/api/platform/monitoringData?client_id=4003&datasource=ML_Training_Only_State_Machine&period=2`,
          'GET',
          null,
          false,
        );
        setIsFetching(false);
      } else {
        if (data !== null && !data?.workflows) {
          Console('done, we have dummy_data - monitoring ', dummy_data, 'info');

          const typeValueMapping = getTypeValueMapping(dummy_data);
          console.log('type val mapping: ', typeValueMapping);

          let dates;
          dates = [
            ...new Set(
              Object.values(typeValueMapping).flatMap((typeData) => typeData.map((dataPoint) => dataPoint.date)),
            ),
          ];
          console.log('dates ', dates);
          //   let scaleY = [];
          // let count = 1;

          const dataset = Object.keys(typeValueMapping).map((key) => {
            const r = Math.floor(Math.random() * 256);
            const g = Math.floor(Math.random() * 256);
            const b = Math.floor(Math.random() * 256);
            //  console.log(key, typeValueMapping[key]);
            console.log(
              'value array ',
              typeValueMapping[key].map((obj) => obj.value),
            );
            const dataToRender = typeValueMapping[key].map((obj) => obj.value);

            //  let position = "left";
            //if (count > 2) position = "right";

            // scaleY[key] = {
            //   id: key,
            //   type: "linear",
            //   display: true,
            //   position: position,
            //   ticks: {
            //     fontColor: "rgba( " + r + ", " + g + "," + b + ", 1)", // this here
            //   },
            // };
            // console.log("scaleY ", scaleY);
            // count++;

            return {
              label: key,
              data: dataToRender,
              lineTension: 0.1,
              borderJoinStyle: 'miter',
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
              fill: false,
              borderColor: 'rgba( ' + r + ', ' + g + ',' + b + ', 1)',
              backgroundColor: 'rgba( ' + r + ', ' + g + ',' + b + ', 0.5)',
              // yAxisID: key,
            };
          });

          setGraphData({
            labels: dates,
            datasets: dataset,
          });

          setGraphOptions({
            responsive: true,
            plugins: {
              legend: {
                position: 'top',
              },
              title: {
                display: false,
                text: 'Chart.js Line Chart',
              },
            },
            //   scales: scaleY,
          });

          console.log(graphData);
          setStep(3);
          // isFetching(false);
        }
      }
    }
  }, [solutionsData, fetchNow, data, loading, isFetching, step, id, graphData]);

  function getTypeValueMapping(data) {
    const typeValueMapping = {};
    data.forEach((dayData) => {
      dayData.Type.forEach((typeData) => {
        if (!typeValueMapping[typeData.type]) {
          typeValueMapping[typeData.type] = [];
        }
        typeValueMapping[typeData.type].push({
          value: typeData.value,
          date: dayData.Date,
        });
      });
    });
    return typeValueMapping;
  }

  /**
   * changes the tab in view on click
   * @param {*} e
   * @param {*} tab
   */
  // function changeTab(e, tab) {
  //   e.preventDefault();
  //   // setPutResponse(null);
  //   var elements = document.getElementsByClassName("active");
  //   elements[0].classList.remove("active");
  //   e.target.parentElement.classList.add("active");
  //   setActiveTab(tab);
  // }

  if (error) {
    console.log('error', error);
    return null;
  }

  return (
    <>
      <div className="dashboard">
        <header>
          <Container fluid>
            <Row>
              <Col lg={8} className="px-5">
                <ul className="breadcrumbs">
                  <Breadcrumb href="/" content="Home" rel="noopener"></Breadcrumb>
                  <Breadcrumb content="Monitoring">Monitoring</Breadcrumb>
                </ul>
                <h1> ML Monitoring Dashboard</h1>
              </Col>
              <Col lg={4} className="px-5"></Col>
            </Row>
          </Container>
        </header>

        <LayoutMonitoring
          sidebar={
            <Tabs
              activeKey={WorkflowactiveTab}
              className="mb-0"
              onSelect={(WorkflowactiveTab) => setWorkflowactiveTab(WorkflowactiveTab)}
            >
              {solutionsData?.map((solution) => {
                return <Tab eventKey={solution.name} title={solution.name}></Tab>;
              })}
            </Tabs>
          }
          children={graphData ? <Line options={graphOptions} data={graphData} /> : null}
          sidebarPos={'left'}
        ></LayoutMonitoring>
      </div>
      <Nav nav1on="on" nav2on="" nav3on="" {...props} />
    </>
  );
};
export { UserMonitoring };
