import { useEffect, useState } from 'react';
import { useAxios } from '../hooks/useAxios';
import { useNavigate, useLocation } from 'react-router-dom';

const XeroSignInFlow = (setAlertType, setShowAlert, setAlertContent) => {
  const navigate = useNavigate();
  const location = useLocation();

  const status_success = 'success';
  const status_error = 'error';
  const status_connected = 'connected';

  const [doingXeroAuth, setDoingAuth] = useState(false);
  const [popup, setPopup] = useState(null);
  const [intervalId, setIntervalId] = useState(null);
  const [doingReferralLogin, setDoingReferralLogin] = useState(false);

  const get_xero_status_url = '/api/platform/provider/xero/status/';
  const get_xero_signin_url = '/api/platform/provider/xero/signin';
  const get_this_app_url = '/api/platform/provider/xero/get-app/';

  const { data: getAppResp = null, fetchNow: getAppURL } = useAxios();
  const { data: SigninUrlResp, fetchNow: getSigninURL } = useAxios();
  const { data: xeroAuthStatus, fetchNow: getXeroConnectionStatus } = useAxios();

  const [subscriptionsPageUrl, setSubscriptionsPageUrl] = useState('');

  const storedXeroSigninId = window.localStorage.getItem('xero_signin_id');

  useEffect(() => {
    if (location.pathname === '/xero-referral') {
      // Handle referral login logic here

      setDoingReferralLogin(true);
      getAppURL(get_this_app_url, 'GET', null, {}, null);
    }
  }, [location]);

  useEffect(() => {
    if (storedXeroSigninId) {
      setDoingReferralLogin(true);
      do_status_check_loop(null, storedXeroSigninId);
    }
  }, [storedXeroSigninId]);

  useEffect(() => {
    if (getAppResp?.status === status_success) {
      // Save signin_id in local storage
      window.localStorage.setItem('xero_signin_id', getAppResp?.signin_id);

      // Redirect the user to the Xero authorization URL within the same window
      window.location.href = getAppResp?.auth_url;
    }
  }, [getAppResp]);

  // effect to handle sign in response
  useEffect(() => {
    if (SigninUrlResp?.status === status_success) {
      openPopup(SigninUrlResp?.auth_url, SigninUrlResp?.signin_id);
    }
  }, [SigninUrlResp]);

  useEffect(() => {
    if (
      xeroAuthStatus?.status === status_success &&
      xeroAuthStatus?.connection_info?.connection_status === status_connected
    ) {
      processAuthTokens();
    } else if (xeroAuthStatus?.status === status_error && !doingXeroAuth) {
      if (xeroAuthStatus?.message) {
        setAlertType('warning');
        setShowAlert(true);
        setAlertContent(xeroAuthStatus?.message + '. \nTo set one up, please  visit: ');
        if (xeroAuthStatus.xero_plans_page_url) {
          setSubscriptionsPageUrl(xeroAuthStatus.xero_plans_page_url);
        }
      }

      clearInterval(intervalId);
    }
  }, [xeroAuthStatus]);

  const startSigninFlow = () => {
    setDoingAuth(true);
    getSigninURL(get_xero_signin_url, 'GET', null, {}, null);
  };

  const processAuthTokens = () => {
    setDoingAuth(false);
    closePopup();

    // auth the user
    window.localStorage.setItem('isAuth', true);
    const auth = {
      expire: Date.now() + 1000 * 60 * 5, // five minute expire
      token: xeroAuthStatus?.platform_access_token,
      refresh_token: xeroAuthStatus?.platform_refresh_token,
    };
    window.localStorage.setItem('auth', JSON.stringify(auth));
    window.localStorage.removeItem('xero_signin_id');

    navigate('/login', {
      state: { doingXeroLogin: true },
    });
  };

  const closePopup = () => {
    if (popup) {
      popup.close();
      setPopup(null);
      if (intervalId) {
        clearInterval(intervalId);
        setIntervalId(null);
        setDoingAuth(true);
      }
    }
  };

  const openPopup = (url, s_id) => {
    const width = 1100;
    const height = 700;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;

    const newPopup = window.open(url, 'XeroAuth', `width=${width},height=${height},top=${top},left=${left}`);

    newPopup.addEventListener('beforeunload', closePopup);

    setPopup(newPopup);

    do_status_check_loop(newPopup, s_id);
  };

  const do_status_check_loop = (newPopup, s_id) => {
    let attempts = 0;
    const id = setInterval(() => {
      const payload = {
        signin_id: s_id,
      };
      getXeroConnectionStatus(get_xero_status_url, 'POST', payload, {}, null);
      if (newPopup?.closed || attempts >= 12) {
        clearInterval(intervalId);
        setIntervalId(null);
        setPopup(null);
        setDoingAuth(false);
        window.localStorage.removeItem('xero_signin_id');
        navigate('/login');
        window.location.reload(false);
      }

      attempts++;
    }, 5000);

    setIntervalId(id);
  };

  useEffect(() => {
    if (popup?.closed) {
      clearInterval(intervalId);
      setIntervalId(null);
      setPopup(null);
      setDoingAuth(false);
    }
  }, [popup]);

  useEffect(() => {
    return () => {
      // Cleanup interval on component unmount
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [intervalId]);

  return { startSigninFlow, subscriptionsPageUrl, doingXeroAuth, doingReferralLogin };
};

export { XeroSignInFlow };
