import React from 'react';
import { BulletTick, IconError } from '../assets/images/icons/icons';

export const findLogByReportMessage = (logs, reportmessage) => {
  if (!logs || !Array.isArray(logs)) {
    return null; // or handle the case where logs is not an array
  }
  return logs.find((log) => log.report_message === reportmessage);
};

export const formatTimestamp = (timestamp, timeZone = 'Pacific/Auckland') => {
  if (!timestamp) {
    return '';
  }

  // Parse the timestamp (either milliseconds or ISO string)
  const date = isNaN(timestamp) ? new Date(timestamp) : new Date(parseInt(timestamp, 10));

  // Convert the date to the specified time zone
  const options = {
    timeZone,
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };
  const localDateString = new Intl.DateTimeFormat('en-NZ', options).format(date);

  return localDateString;
};

function extractEmailFromRecipient(recipient) {
  const emailPattern = /<([^>]+)>/;
  const match = recipient.match(emailPattern);
  return match ? match[1] : null;
}

// Helper function to render status messages with icons
export const renderStatusMessage = (
  message,
  status,
  successStatuses = ['Success', 'Yes'],
  successIcon,
  errorIcon,
  title,
) => (
  <div className="tooltip clear">
    {title && <span className="tooltiptext">{title}</span>}
    {message} &nbsp;
    {successStatuses.includes(status) ? (
      <img src={successIcon} alt={`${message} succeeded`} />
    ) : (
      <img className="icon-error" src={errorIcon} alt={`${message} failed`} />
    )}
  </div>
);

export const renderToolTipMessage = (message, Icon, title) => (
  <div className="tooltip clear">
    {title && <span className="tooltiptext">{title}</span>}
    {message} &nbsp;
    <img src={Icon} alt={`${message}`} width={16} />
  </div>
);

export const build_emailDetectedCol = (logs) => {
  let output = [];
  const newEmailArrived = findLogByReportMessage(logs, 'NEW_EMAIL_ARRIVED');
  if (newEmailArrived) {
    const { subject, sender, recipient } = newEmailArrived;
    const subjectText = subject ? subject : 'Subject not found';
    const fromText = sender ? sender : 'Sender not found';
    const toText = recipient ? extractEmailFromRecipient(recipient) : 'Receiver not found';

    output.push(
      <>
        <strong>Arrived</strong> {formatTimestamp(newEmailArrived['@timestamp'])}
        <div key="email-subject">
          <strong>Email subject:</strong> {subjectText} &nbsp;
        </div>
        <div key="email-to-from">
          <div>
            <strong>From:</strong> {fromText}
          </div>
          <div>
            <strong>To:</strong> {toText}
          </div>
        </div>
      </>,
    );
  }
  return output;
};

export const build_invTriagedCol = (logs) => {
  let output = [];

  const attachmentsLog = findLogByReportMessage(logs, 'NO_INVOICES_ATTACHED');
  if (attachmentsLog) {
    output.push(renderToolTipMessage('Contains invoices', IconError, 'no attachment found'));
  } else {
    output.push(renderToolTipMessage('Contains invoices', BulletTick, 'attachment found'));
  }

  const vendor_found = findLogByReportMessage(logs, 'VENDOR_FOUND');
  if (vendor_found) {
    output.push(
      <div key="v-a-a-message">
        Vendor Found&nbsp; <img className="icon-error" src={BulletTick} alt="vendor found" />
      </div>,
    );
  }
  const approvalRequestSent = findLogByReportMessage(logs, 'SENT_APPROVAL_REQUEST');
  if (approvalRequestSent) {
    output.push(
      <div key="v-a-a-message">
        Vendor Not Found&nbsp; <img className="icon-error" src={IconError} alt="vendor does not exist" />
      </div>,
    );
  }

  const inv_not_exist_log = findLogByReportMessage(logs, 'INVOICE_NOT_EXIST');
  if (inv_not_exist_log) {
    output.push(renderToolTipMessage('Not a duplicate', BulletTick, 'invoice does not exist'));
  }

  const inv_exist_log = findLogByReportMessage(logs, 'INVOICE_EXISTS');
  if (inv_exist_log) {
    output.push(
      <div key="duplicate-message">
        Is Duplicate&nbsp;
        <img className="icon-error" src={IconError} alt="invoice does exist" />
      </div>,
    );
  }

  const approval_log = findLogByReportMessage(logs, 'APPROVED_TO_PROCESS');
  if (approval_log) {
    output.push(renderToolTipMessage('Vendor Approved', BulletTick, approval_log.message));
  }
  const vendor_check_log = findLogByReportMessage(logs, 'VENDOR_CREATED');
  if (vendor_check_log) {
    output.push(renderToolTipMessage('Vendor Created', BulletTick, vendor_check_log.message));
  }

  return output;
};

export const build_invExCol = (logs) => {
  let output = [];

  const lineitemsLog = findLogByReportMessage(logs, 'INVOICE_LINE_ITEMS_FOUND', true);
  if (lineitemsLog) {
    output.push(renderToolTipMessage('Line Items', BulletTick, 'Line Items Exist'));
  } else {
    output.push(renderToolTipMessage('Line Items', IconError, 'Line Items Not Found'));
  }

  const referenceLog = findLogByReportMessage(logs, 'INVOICE_REFERENCE');
  if (referenceLog) {
    output.push(<div key="attachments">Ref: {referenceLog}</div>);
  }

  const draftdiffer = findLogByReportMessage(logs, 'DRAFT_BILL_TOTALS_DIFFER');
  if (draftdiffer) {
    output.push(renderToolTipMessage('Totals differ Xero', IconError, draftdiffer.message));
  }

  const draftMatch = findLogByReportMessage(logs, 'DRAFT_BILL_TOTALS_MATCH');
  if (draftMatch) {
    output.push(renderToolTipMessage('Total matches Xero', BulletTick, draftMatch.message));
  }

  return output;
};

export const build_draftBillCol = (logs) => {
  let output = [];

  const draftCreated = findLogByReportMessage(logs, 'DRAFT_BILL_CREATED_IN_XERO');
  if (draftCreated) {
    output.push(renderToolTipMessage('Draft Bill Created in Xero', BulletTick, draftCreated.message));
  } else {
    output.push(<div key="draft-bill-not-created">Null</div>);
  }

  return output;
};

export const build_emailSentCol = (logs) => {
  let output = [];

  const approvalRequestSent = findLogByReportMessage(logs, 'SENT_APPROVAL_REQUEST');
  if (approvalRequestSent) {
    output.push(renderToolTipMessage('Vendor Approval Request Sent', BulletTick, approvalRequestSent.message));
  }

  return output;
};

export const JobsTable = ({ jobLogs }) => {
  return (
    <table id="jobs-table">
      <thead>
        <tr>
          <th style={{ width: '20px' }}>JobID</th>
          <th>Email Detected in Inbox</th>

          <th>Invoice Triaged</th>
          <th>Invoice Extracted</th>
          <th>Draft Bill Created</th>
          <th>Emails Archie Sent</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(jobLogs).map((jobFlowId, logsIndex) => (
          <React.Fragment key={jobFlowId}>
            {Object.keys(jobLogs[jobFlowId]?.baseLogs || {}).map((baseLogId, index) => (
              <tr key={index} style={{ backgroundColor: logsIndex % 2 === 0 ? '#f2f2f2' : '#ffffff' }}>
                <td>{jobFlowId}</td>
                <td>{build_emailDetectedCol(jobLogs[jobFlowId]?.baseLogs[baseLogId])}</td>

                <td></td>
                <td></td>
                <td></td>
                <td>{build_emailSentCol(jobLogs[jobFlowId]?.baseLogs[baseLogId])}</td>
              </tr>
            ))}

            {Object.keys(jobLogs[jobFlowId]?.subLogs || {}).map((subLogsId, index) => (
              <tr key={index} style={{ backgroundColor: logsIndex % 2 === 0 ? '#f2f2f2' : '#ffffff' }}>
                <td>-</td>
                <td>Attachment Item</td>

                <td>{build_invTriagedCol(jobLogs[jobFlowId]?.subLogs[subLogsId])}</td>
                <td>{build_invExCol(jobLogs[jobFlowId]?.subLogs[subLogsId])}</td>
                <td>{build_draftBillCol(jobLogs[jobFlowId]?.subLogs[subLogsId])}</td>
                <td>{build_emailSentCol(jobLogs[jobFlowId]?.subLogs[subLogsId])}</td>
              </tr>
            ))}
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );
};

/**
 * Take the jobLogs and return a summary of the data
 */
export const jobsSummary = (jobLogs) => {
  // Aggregate all log items into a single array
  const logsArray = Object.values(jobLogs).flatMap((jobGroup) =>
    [...Object.values(jobGroup.baseLogs || {}), ...Object.values(jobGroup.subLogs || {})].flat(),
  );

  const output = logsArray.reduce(
    (acc, item) => {
      if (item && item['@timestamp']) {
        const { '@timestamp': Timestamp, report_message } = item;
        const timestamp = formatTimestamp(Timestamp);

        if (report_message === 'NEW_EMAIL_ARRIVED') {
          acc.totalEmailsSeen += 1;
          if (!acc.lastEmailSeen || new Date(timestamp) > new Date(acc.lastEmailSeen)) {
            acc.lastEmailSeen = timestamp;
          }
        } else if (report_message === 'DRAFT_BILL_CREATED_IN_XERO') {
          acc.totalDraftBillsCreated += 1;
        } else if (report_message === 'INVOICE_LINE_ITEMS_FOUND') {
          acc.totalInvoicesDetected += 1;
        }
      }

      return acc;
    },
    {
      lastEmailSeen: null,
      totalEmailsSeen: 0,
      totalInvoicesDetected: 0,
      totalDraftBillsCreated: 0,
      extractionRate: 0,
      billCreationRate: 0,
    },
  );

  // Calculate extraction rate
  if (output.totalEmailsSeen > 0) {
    output.extractionRate = ((output.totalInvoicesDetected / output.totalEmailsSeen) * 100).toFixed(1);
  } else {
    output.extractionRate = 0.0;
  }

  // Calculate draf bill creation rate
  if (output.totalDraftBillsCreated > 0) {
    output.billCreationRate = ((output.totalDraftBillsCreated / output.totalInvoicesDetected) * 100).toFixed(1);
  } else {
    output.billCreationRate = 0.0;
  }

  return output;
};
