import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { WorkflowLinks } from './workflowlinks';
import { Link } from 'react-router-dom';
import { ArchieImg, ArcosImg, ArcelImg, ArchanaImg } from '../assets/images/images';

const CardSolution = ({ icon, title, content, id, status, assistant_type }) => {
  return (
    <>
      <div className="card card-solutions">
        {assistant_type === 'archie' ? (
          <a href={`/setup-archie`} rel="noopener" id={`solution-${id}`}>
            <CardContents />
          </a>
        ) : !status ? (
          <a href={`/solution/${id}/${title.replace(/\s+/g, '-')}`} rel="noopener" id={`solution-${id}`}>
            <CardContents />
          </a>
        ) : (
          <CardContents />
        )}
      </div>
    </>
  );

  function CardContents() {
    return (
      <>
        <div className="card-header">
          <Row>
            <Col lg={9}>
              <img src={icon} alt="" />
              {title}
            </Col>
            <Col lg={3} className="right">
              {title === 'Finance' || assistant_type === 'archie' ? (
                <img src={ArchieImg} className="ai_assistant" alt="Archie" />
              ) : title === 'Operations' ? (
                <img src={ArcosImg} className="ai_assistant" alt="Arcos" />
              ) : title === 'Customer Experience' ? (
                <img src={ArcelImg} className="ai_assistant" alt="Arcel" />
              ) : title === 'Sustainability' ? (
                <img src={ArchanaImg} className="ai_assistant" alt="Archana" />
              ) : null}
            </Col>
          </Row>
        </div>
        <div className="card-body">{content}</div>
        <div className="card-buttons">
          <Row>
            <Col lg={8}>
              <WorkflowLinks title={title} />
            </Col>
            <Col lg={4}>
              {status === 'coming_soon' ? <div className="badge-status comingsoon right">Coming Soon</div> : ''}
            </Col>
          </Row>
        </div>
        <div className="card-footer" />
      </>
    );
  }
};

export { CardSolution };
