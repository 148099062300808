import React from 'react';
import {
  IconWf1,
  IconWf2,
  IconWf3,
  IconWf4,
  IconWf5,
  IconWf6,
  IconWf7,
  IconWf8,
  IconWf9,
  IconWf10,
  IconWf11,
  IconWf12,
} from '../assets/images/icons/icons';

const WorkflowLinks = ({ title }) => {
  if (title === 'Finance') {
    return (
      <>
        <img src={IconWf1} alt="" className="icon-solutions" />
        <img src={IconWf2} alt="" className="icon-solutions" />
        <img src={IconWf3} alt="" className="icon-solutions" />
        <img src={IconWf4} alt="" className="icon-solutions" />
        <img src={IconWf5} alt="" className="icon-solutions" />
      </>
    );
  } else if (title === 'Operations') {
    return (
      <>
        <img src={IconWf6} alt="" className="icon-solutions" />
        <img src={IconWf7} alt="" className="icon-solutions" />
        <img src={IconWf8} alt="" className="icon-solutions" />
        <img src={IconWf4} alt="" className="icon-solutions" />
        <img src={IconWf9} alt="" className="icon-solutions" />
        <img src={IconWf10} alt="" className="icon-solutions" />
      </>
    );
  } else if (title === 'Customer Experience') {
    return (
      <>
        <img src={IconWf11} alt="" className="icon-solutions" />
        <img src={IconWf2} alt="" className="icon-solutions" />
        <img src={IconWf6} alt="" className="icon-solutions" />
        <img src={IconWf4} alt="" className="icon-solutions" />
      </>
    );
  } else {
    return (
      <>
        <img src={IconWf1} alt="" className="icon-solutions" />
        <img src={IconWf12} alt="" className="icon-solutions" />
        <img src={IconWf10} alt="" className="icon-solutions" />
        <img src={IconWf4} alt="" className="icon-solutions" />
      </>
    );
  }
};

export { WorkflowLinks };
